/*-----------------------------------------------------------------------------------
    Template Name: Hotel Miranda
	Description: Hoetel Resort Booking HTML Template
    Author: AndroThemes
    Author URI: https://themeforest.net/user/androThemes
    Version: 1.0

    Note: This is Main Style Scss File.
-----------------------------------------------------------------------------------
    CSS INDEX
    ===================
    Common
    Header
    -- Off canvas menu
    -- Breadcrumb CSS
    Banner
    About
    Room Tab
    Call TO Action
    Text Block
    Testimonial
    Latest Post
    Core Feature
    -- Dark Style
    Room
    -- Room Gird
    -- Room List
    -- Room Slider
      -- Room Slider One
      -- Room Slider Two
    -- Room Details
    Counter
    Our Menu
    Contact
    -- Contact info box
    Offers
    Gallery
    News
    -- News Details
    -- Paginations
    -- Sidebars
    Booking Form
    Places
    -- Places Details
    Restaurant
    Footer
    -- Subscibe
    -- Widgets
    -- Copy Right
    -- Back to top

-----------------------------------------------------------------------------------*/
/*=======  COMMON CSS  =======*/
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&family=Old+Standard+TT:ital,wght@0,400;0,700;1,400&family=Roboto:wght@400;500;700&display=swap");
html{
  overflow-x: hidden;
  overflow-y: auto;
}
body {
  font-family: "Archivo", sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #777;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.6;
  background-color: #f8f8f8;
}
*:focus,
.slick-initialized .slick-slide:focus {
    outline: none;
}
@media (max-width: 399px) {
  body {
    font-size: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.slick-initialized .slick-slide:focus,
.btn:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Old Standard TT", serif;
  font-weight: 400;
  margin: 0px;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
  color: #222;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  margin: 0px;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a, a:hover {
  color: #bead8e;
}

input,
select,
textarea,
select {
  border: none;
  width: 100%;
  height: 70px;
  background-color: #f8f8f8;
  padding: 0 40px;
}

textarea {
  height: 250px;
  padding-top: 25px;
}

::-webkit-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

:-ms-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

::-ms-input-placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

::placeholder,
input,
textarea,
select {
  font-family: "Roboto", sans-serif;
  color: #777777;
}

.nice-select {
  width: 100%;
  background-color: transparent;
  border-radius: 0;
  position: relative;
  border: none;
  height: 70px;
  padding-left: 50px;
  line-height: 70px;
}

.nice-select .list {
  width: 100%;
  border-radius: 0;
}

.nice-select .list li {
  line-height: 35px;
  color: #777;
}

.nice-select .list li:hover, .nice-select .list li.option:hover {
  background-color: #bead8e;
  color: #fff;
}

.nice-select::after {
  display: none;
}

.nice-select::before {
  content: '\f063';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  position: absolute;
  left: 30px;
  top: 50%;
  color: #bead8e;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input-group input,
.input-group select,
.input-group textarea,
.input-group select {
  padding-left: 80px;
}

.input-group label {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #222;
  display: block;
}

.input-group .icon {
  position: absolute;
  left: 40px;
  bottom: 35px;
  color: #bead8e;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.input-group.textarea .icon {
  top: 25px;
  bottom: auto;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.input-group .nice-select {
  padding-left: 80px;
}

.input-group .nice-select::before {
  left: 40px;
}

.input-group.input-group-two input,
.input-group.input-group-two select,
.input-group.input-group-two textarea,
.input-group.input-group-two select {
  height: 60px;
  padding: 0px 50px 0 30px;
  background-color: #fff;
  color: #777;
}

.input-group.input-group-two ::-webkit-input-placeholder {
  opacity: 1;
  color: #777;
}

.input-group.input-group-two :-ms-input-placeholder {
  opacity: 1;
  color: #777;
}

.input-group.input-group-two ::-ms-input-placeholder {
  opacity: 1;
  color: #777;
}

.input-group.input-group-two ::placeholder {
  opacity: 1;
  color: #777;
}

.input-group.input-group-two .icon {
  left: auto;
  bottom: 30px;
  right: 30px;
}

.input-group.input-group-two .nice-select {
  background-color: #fff;
  padding: 0 50px 0 30px;
  height: 60px;
  line-height: 60px;
}

.input-group.input-group-two .nice-select::before {
  left: auto;
  right: 30px;
}

/*=======  COMMON CLASS  =======*/
@media (min-width: 1600px) {
  .container-custom-one {
    max-width: 1600px;
  }
}

@media (min-width: 1600px) {
  .container-custom-two {
    max-width: 1400px;
  }
}

@media (min-width: 1600px) {
  .container-custom-three {
    max-width: 1420px;
  }
}

.slick-slide img {
  display: inline-block;
}

.bg-black {
  background-color: #222;
}

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0 48px;
  font-size: 14px;
  line-height: 60px;
  border-radius: 0px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #777;
  border: 2px solid #bead8e;
}

.main-btn.btn-filled {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}

.main-btn.btn-filled:hover {
  background-color: transparent;
  color: #777;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.main-btn.btn-border {
  border-color: #bead8e;
}

.main-btn.btn-border:hover {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.4);
}

.section-title span.title-tag {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 20px;
}

@media (max-width: 767px) {
  .section-title span.title-tag {
    letter-spacing: 2px;
  }
}

@media (max-width: 399px) {
  .section-title span.title-tag {
    font-size: 12px;
  }
}

.section-title h2 {
  font-size: 80px;
  line-height: 1.125;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 62px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .section-title h2 {
    font-size: 42px;
  }
}

@media (max-width: 399px) {
  .section-title h2 {
    font-size: 32px;
  }
}

.section-title.white-color span.title-tag {
  color: #bead8e;
}

.section-title.white-color h2 {
  color: #fff;
}

.arrow-style {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.arrow-style .slick-arrow {
  height: 70px;
  width: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 18px;
  color: #111;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.arrow-style .slick-arrow.next-arrow {
  margin-left: 20px;
}

.arrow-style .slick-arrow:hover, .arrow-style .slick-arrow.next-arrow {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
}

/*=======  Preloader  =======*/
.preloader {
  background-color: #bead8e;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
}

.preloader .cssload-container {
  display: block;
  width: 97px;
}

.preloader .cssload-loading i {
  width: 19px;
  height: 19px;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
}

.preloader .cssload-loading i:first-child {
  opacity: 0;
  -webkit-animation: cssload-loading-ani2 0.58s linear infinite;
          animation: cssload-loading-ani2 0.58s linear infinite;
  -webkit-transform: translate(-19px);
          transform: translate(-19px);
}

.preloader .cssload-loading i:nth-child(2),
.preloader .cssload-loading i:nth-child(3) {
  -webkit-animation: cssload-loading-ani3 0.58s linear infinite;
          animation: cssload-loading-ani3 0.58s linear infinite;
}

.preloader .cssload-loading i:last-child {
  -webkit-animation: cssload-loading-ani1 0.58s linear infinite;
          animation: cssload-loading-ani1 0.58s linear infinite;
}

/*=======  ANIMATION  =======*/
@-webkit-keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}
@keyframes sticky {
  0% {
    top: -200px;
  }
  100% {
    top: 0;
  }
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border-2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
            transform: translate(39px);
    opacity: 0;
  }
}

@keyframes cssload-loading-ani1 {
  100% {
    -webkit-transform: translate(39px);
            transform: translate(39px);
    opacity: 0;
  }
}

@-webkit-keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
    opacity: 1;
  }
}

@keyframes cssload-loading-ani2 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
    opacity: 1;
  }
}

@-webkit-keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
  }
}

@keyframes cssload-loading-ani3 {
  100% {
    -webkit-transform: translate(19px);
            transform: translate(19px);
  }
}

@-webkit-keyframes zoomIn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes zoomIn {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}

@keyframes hvr-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}

/*=======  HEADER CSS  =======*/
header.header-absolute {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: transparent;
  top: 35px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  header .container.container-custom-one {
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .container.container-custom-one {
    max-width: 100%;
  }
}

header .nav-container .toggle {
  margin-right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-container .toggle {
    margin-right: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-container .toggle {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header .nav-container .toggle {
    display: none;
  }
}

@media (max-width: 767px) {
  header .nav-container .toggle {
    display: none;
  }
}

header .nav-container .toggle a {
  color: #222;
  font-size: 24px;
  padding: 10px;
}

header .nav-container .site-logo img {
  max-width: 250px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-container .site-logo img {
    max-width: 200px;
  }
}

@media (max-width: 575px) {
  header .nav-container .site-logo img {
    max-width: 180px;
  }
}

header .nav-container .site-logo .sticky-logo {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header .nav-container .header-info {
    padding: 40px 20px 0;
  }
}

@media (max-width: 767px) {
  header .nav-container .header-info {
    padding: 40px 20px 0;
  }
}

header .nav-container .header-info .item {
  padding-left: 50px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header .nav-container .header-info .item {
    color: #fff;
  }
}

@media (max-width: 767px) {
  header .nav-container .header-info .item {
    color: #fff;
  }
}

header .nav-container .header-info .item i {
  position: absolute;
  left: 0;
  top: 6px;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 4px;
  background: #111;
  color: #fff;
  font-size: 14px;
}

header .nav-container .header-info .item span {
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 4px;
}

header .nav-container .header-info .item a {
  display: block;
}

header .nav-container .header-info .item .title {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  header .nav-container .header-info .item .title {
    font-size: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-container .header-info .item .title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header .nav-container .header-info .item .title {
    color: #fff;
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  header .nav-container .header-info .item .title {
    color: #fff;
    font-size: 22px;
  }
}

header .nav-container .header-info .item:not(:last-child) {
  margin-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  header .nav-container .header-info .item:not(:last-child) {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-container .header-info .item:not(:last-child) {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header .nav-container .header-info .item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  header .nav-container .header-info .item:not(:last-child) {
    margin-right: 0;
    margin-bottom: 30px;
  }
}

header .nav-container .menu-items ul li {
  display: inline-block;
  position: relative;
}

header .nav-container .menu-items ul li a {
  font-size: 16px;
  font-weight: 600;
  color: #111;
  text-transform: capitalize;
  line-height: 80px;
  padding: 0px 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  header .nav-container .menu-items ul li a {
    font-size: 14px;
    padding: 0 10px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header .nav-container .menu-items ul li a {
    padding: 0 8px;
  }
}

header .nav-container .menu-items ul li .submenu {
  position: absolute;
  left: 0;
  top: 110%;
  width: 200px;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 99;
  height: auto;
}

header .nav-container .menu-items ul li .submenu li {
  display: block;
}

header .nav-container .menu-items ul li .submenu li a {
  display: block;
  padding: 8px 24px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0;
  margin: 0 0;
  line-height: 30px !important;
  border-bottom: 1px solid rgba(242, 244, 248, 0.7);
  color: #777;
}

header .nav-container .menu-items ul li .submenu li a:hover {
  background-color: #bead8e;
  color: #fff !important;
  border-color: #bead8e;
}

header .nav-container .menu-items ul li .submenu li .submenu {
  left: 100%;
  top: 50%;
}

header .nav-container .menu-items ul li .submenu li:hover .submenu {
  top: 0;
}

header .nav-container .menu-items ul li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

header .nav-container .menu-items ul li .dd-trigger {
  display: none;
}

header .nav-container .nav-pushed-item {
  display: none;
}

header .nav-container .navbar-toggler {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: none;
}

header .nav-container .navbar-toggler span {
  position: relative;
  background-color: #222;
  border-radius: 3px;
  display: block;
  height: 3px;
  margin-top: 5px;
  padding: 0;
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
  width: 30px;
  cursor: pointer;
  display: block;
}

header .nav-container .navbar-toggler.active span:nth-of-type(1) {
  -webkit-transform: rotate3d(0, 0, 1, 45deg);
          transform: rotate3d(0, 0, 1, 45deg);
  top: 8px;
}

header .nav-container .navbar-toggler.active span:nth-of-type(2) {
  opacity: 0;
}

header .nav-container .navbar-toggler.active span:nth-of-type(3) {
  -webkit-transform: rotate3d(0, 0, 1, -45deg);
          transform: rotate3d(0, 0, 1, -45deg);
  top: -8px;
}

header .nav-container .navbar-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 12;
  display: none;
}

header .nav-container .navbar-close .cross-wrap {
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
}

header .nav-container .navbar-close .cross-wrap span {
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 6px;
  background: #fff;
}

header .nav-container .navbar-close .cross-wrap span.top {
  top: 12px;
  left: 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

header .nav-container .navbar-close .cross-wrap span.bottom {
  bottom: 12px;
  left: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}


header .nav-container .navbar-toggler{
  display: none;
}
header.header-two .nav-container .toggle a,
header.header-two .nav-container .menu-items ul li a {
  color: #fff;
}

header.header-two .nav-container .navbar-toggler span {
  background-color: #fff;
}

header.header-two .header-info .item i {
  background: #bead8e;
}


@media(max-width: 1200px){
  header .nav-container .nav-push-item{
    display: none;
  }
  header .nav-container .nav-pushed-item,
  header .nav-container .navbar-close,
  header .nav-container .navbar-toggler{
    display: block;
  }
  header .nav-container .nav-menu {
    background-color: #bead8e;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 9999;
    width: 300px;
    height: 100%;
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    padding: 0;
    -webkit-box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
    display: block;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 70px;
  }

  header .nav-container .nav-menu .menu-items ul li.menu-item-has-children > a{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  header .nav-container .nav-menu .menu-items ul li.menu-item-has-children > .submenu.open{
    display: block;
  }
  header .nav-container .nav-menu .menu-items ul li.menu-item-has-children > a::after{
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }

  header .nav-container .nav-menu .menu-items ul li {
    display: block;
  }

  header .nav-container .nav-menu .menu-items ul li a {
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff !important;
    line-height: 45px !important;
  }

  header .nav-container .nav-menu .menu-items ul li .submenu {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    background-color: transparent;
    visibility: visible;
    opacity: 1;
    display: none;
    -webkit-transition: none;
    transition: none;
  }

  header .nav-container .nav-menu .menu-items ul li .submenu li a {
    color: #fff;
    padding: 0px 20px 0 40px;
    line-height: 45px !important;
  }

  header .nav-container .nav-menu .menu-items ul li .submenu li a:hover {
    border-color: rgba(255, 255, 255, 0.5);
  }

  header .nav-container .nav-menu .menu-items ul li .dd-trigger {
    display: block;
    position: absolute;
    right: 0;
    height: 45px;
    width: 45px;
    top: 0;
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 2;
    background: transparent;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }

  header .nav-container .nav-menu.menu-on {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.header-two .header-info .item i {
    background: #111;
  }
}

@media (max-width: 767px) {
  header.header-two .header-info .item i {
    color: #111;
  }
}

header.header-two .header-info .item a, header.header-two .header-info .item,
header.header-two .header-info .item .title {
  color: #fff;
}

header.header-three {
  background-color: transparent;
  position: relative;
}

header.header-three .main-menu-area {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99;
  height: auto;
  bottom: -60px;
}

@media (max-width: 399px) {
  header.header-three .main-menu-area {
    position: relative;
    bottom: 0;
    top: 0;
  }
}

header.header-three .main-menu-area.sticky-header.sticky-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  z-index: 999;
  -webkit-animation: sticky 1.2s;
          animation: sticky 1.2s;
}

header.header-three .main-menu-area.sticky-header.sticky-active .nav-container {
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
}

header.header-three .main-menu-area.sticky-header.sticky-active .nav-container .menu-items ul li a {
  line-height: 100px;
}

header.header-three .header-top {
  padding-top: 15px;
  padding-bottom: 75px;
  font-family: "Roboto", sans-serif;
  background-color: #222;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.header-three .header-top {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  header.header-three .header-top {
    text-align: center;
    padding-bottom: 50px;
  }
}

@media (max-width: 399px) {
  header.header-three .header-top {
    display: none;
  }
}

header.header-three .header-top,
header.header-three .header-top a {
  color: #fff;
}

@media (max-width: 767px) {
  header.header-three .header-top ul.header-top-info {
    margin-top: 10px;
  }
}

header.header-three .header-top ul.header-top-info li {
  display: inline-block;
  margin-left: 15px;
}

@media (max-width: 575px) {
  header.header-three .header-top ul.header-top-info li {
    margin-left: 5px;
  }
}

header.header-three .header-top ul.header-top-info li i {
  margin-right: 10px;
  color: #bead8e;
}

header.header-three .nav-container {
  background-color: #fff;
  padding: 0 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header.header-three .nav-container {
    padding: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.header-three .nav-container {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  header.header-three .nav-container {
    padding: 10px;
  }
}

@media (max-width: 399px) {
  header.header-three .nav-container {
    padding: 10px 5px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  header.header-three .nav-container .site-logo img {
    max-width: 200px;
  }
}

@media (max-width: 399px) {
  header.header-three .nav-container .site-logo img {
    max-width: 150px;
  }
}

header.header-three .nav-container .menu-items ul li a {
  line-height: 120px;
}

header.header-three .nav-container .menu-items ul li .submenu li a {
  line-height: 30px;
}

header.header-three .nav-container .menu-right-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header.header-three .nav-container .menu-right-buttons .toggle {
  margin-right: 0;
}

header.header-three .nav-container .menu-right-buttons > div {
  margin-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  header.header-three .nav-container .menu-right-buttons > div {
    margin-left: 5px;
  }
}

@media (max-width: 767px) {
  header.header-three .nav-container .menu-right-buttons > div {
    margin-left: 5px;
  }
}

header.header-three .nav-container .menu-right-buttons .navbar-toggler {
  border: 2px solid rgba(133, 133, 133, 0.2);
  padding: 15px;
}

@media (max-width: 575px) {
  header.header-three .nav-container .menu-right-buttons .navbar-toggler {
    padding: 5px 10px;
  }
  header.header-three .nav-container .menu-right-buttons .navbar-toggler span {
    width: 20px;
  }
}

header.header-three .nav-container .menu-right-buttons a {
  width: 65px;
  height: 65px;
  border: 2px solid rgba(133, 133, 133, 0.2);
  text-align: center;
  line-height: 65px;
  padding: 0;
  color: #111;
}

@media (max-width: 575px) {
  header.header-three .nav-container .menu-right-buttons a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}

header.header-three .nav-container .menu-right-buttons a:hover {
  background-color: #bead8e;
  color: #fff;
}

header.header-three .search {
  position: relative;
}

header.header-three .search .search-icon {
  position: relative;
  display: block;
}

header.header-three .search .search-icon .close-icon,
header.header-three .search .search-icon .open-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 2;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

header.header-three .search .search-icon .close-icon {
  visibility: hidden;
  opacity: 0;
}

header.header-three .search .search-icon.active .close-icon {
  visibility: visible;
  opacity: 1;
}

header.header-three .search .search-icon.active .open-icon {
  visibility: hidden;
  opacity: 0;
}

header.header-three .search .search-form {
  position: absolute;
  right: 0;
  width: 350px;
  display: none;
  top: calc(100% + 27px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.header-three .search .search-form {
    top: calc(100% + 17px);
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  header.header-three .search .search-form {
    top: calc(100% + 10px);
  }
}

@media (max-width: 575px) {
  header.header-three .search .search-form {
    top: calc(100% + 20px);
    width: 250px;
  }
}

@media (max-width: 399px) {
  header.header-three .search .search-form {
    right: -100%;
    top: calc(100% + 12px);
  }
}

header.header-three .search .search-form form {
  position: relative;
}

header.header-three .search .search-form form input {
  width: 100%;
  background-color: #f8f8f8;
  height: 60px;
  border: none;
  padding-left: 30px;
  padding-right: 60px;
  font-size: 14px;
}

@media (max-width: 575px) {
  header.header-three .search .search-form form input {
    height: 50px;
  }
}

header.header-three .search .search-form form button {
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 1;
  background-color: #bead8e;
  font-size: 14px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

header.header-three .search .search-form form button:hover {
  background-color: #111;
  color: #fff;
}

header.inner-page.header-absolute {
  background-color: #fff;
  top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.inner-page.header-absolute .nav-container {
    padding: 10px 0;
  }
}

@media (max-width: 767px) {
  header.inner-page.header-absolute .nav-container {
    padding: 10px 0;
  }
}

header.inner-page.header-absolute .nav-container .menu-items ul li a {
  line-height: 130px;
}

header.sticky-header.sticky-active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.04);
  z-index: 999;
  -webkit-animation: sticky 1.2s;
          animation: sticky 1.2s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.sticky-header.sticky-active .nav-container {
    padding: 10px 0;
  }
  header.sticky-header.sticky-active .nav-container .site-logo img {
    max-width: 200px;
  }
}

@media (max-width: 767px) {
  header.sticky-header.sticky-active .nav-container {
    padding: 10px 0;
  }
}

header.sticky-header.sticky-active .nav-container .menu-items ul li a {
  line-height: 100px;
}

header.sticky-header.sticky-active .nav-container .site-logo .main-logo {
  display: none;
}

header.sticky-header.sticky-active .nav-container .site-logo .sticky-logo {
  display: block;
}

header.sticky-header.sticky-active.header-two .nav-container .toggle a,
header.sticky-header.sticky-active.header-two .nav-container .menu-items ul li a {
  color: #111;
}

header.sticky-header.sticky-active.header-two .nav-container .navbar-toggler span {
  background-color: #111;
}

header.sticky-header.sticky-active.header-two .header-info .item a, header.sticky-header.sticky-active.header-two .header-info .item,
header.sticky-header.sticky-active.header-two .header-info .item .title {
  color: #111;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  header.sticky-header.sticky-active.header-two .header-info .item a, header.sticky-header.sticky-active.header-two .header-info .item,
  header.sticky-header.sticky-active.header-two .header-info .item .title {
    color: #fff;
  }
}

@media (max-width: 767px) {
  header.sticky-header.sticky-active.header-two .header-info .item a, header.sticky-header.sticky-active.header-two .header-info .item,
  header.sticky-header.sticky-active.header-two .header-info .item .title {
    color: #fff;
  }
}

/*=======  Off canvas menu  =======*/
.offcanvas-wrapper {
  position: fixed;
  width: 450px;
  top: 0;
  min-height: 100vh;
  left: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  margin-left: -450px;
}

.offcanvas-wrapper.show-offcanvas {
  margin-left: 0;
  opacity: 1;
  visibility: visible;
}

.offcanvas-wrapper .offcanvas-overly {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 98;
  background-color: #1e1e1e;
  left: 0;
  top: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.offcanvas-wrapper .offcanvas-overly.show-overly {
  visibility: visible;
  opacity: 0.5;
}

.offcanvas-wrapper .offcanvas-widget {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 70px 40px;
  position: relative;
  z-index: 99;
}

.offcanvas-wrapper .offcanvas-widget a.offcanvas-close {
  position: absolute;
  right: 30px;
  top: 30px;
  font-size: 28px;
  color: #bead8e;
}

.offcanvas-wrapper .offcanvas-widget .widget {
  margin-bottom: 50px;
  font-size: 14px;
  /* Search Widget */
  /* Nav Widget */
  /* Social Link */
}

.offcanvas-wrapper .offcanvas-widget .widget .widget-title {
  font-size: 20px;
  margin-bottom: 25px;
  line-height: 1;
  position: relative;
  padding-left: 30px;
}

.offcanvas-wrapper .offcanvas-widget .widget .widget-title::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #bead8e;
  content: '';
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form {
  position: relative;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form input {
  width: 100%;
  background-color: #f8f8f8;
  height: 60px;
  border: none;
  padding-left: 30px;
  padding-right: 60px;
  font-size: 14px;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form button {
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 1;
  background-color: #bead8e;
  font-size: 14px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.offcanvas-wrapper .offcanvas-widget .widget.search-widget form button:hover {
  background-color: #111;
  color: #fff;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li {
  line-height: 30px;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li a {
  color: #777;
  font-weight: 700;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li a:hover {
  padding-left: 10px;
  color: #bead8e;
}

.offcanvas-wrapper .offcanvas-widget .widget.nav-widget ul li .submenu {
  padding-left: 10px;
}

.offcanvas-wrapper .offcanvas-widget .widget.social-link ul li {
  display: inline-block;
  margin-right: 5px;
}

.offcanvas-wrapper .offcanvas-widget .widget.social-link ul li a {
  height: 40px;
  width: 40px;
  background-color: #bead8e;
  color: #fff;
  line-height: 40px;
  text-align: center;
}

.offcanvas-wrapper .offcanvas-widget .widget.social-link ul li a:hover {
  background-color: #222;
}

/*=======  Breadcrumb CSS  =======*/
.breadcrumb-area {
  background-size: cover;
  background-position: center;
  position: relative;
  background-image: url(../img/korčula.jpg) !important;
  z-index: 1;
  margin-top: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .breadcrumb-area {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area {
    margin-top: 100px;
  }
}

@media (max-width: 575px) {
  .breadcrumb-area {
    margin-top: 80px;
  }
}

.breadcrumb-area::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  content: '';
  opacity: 0.7;
}

.breadcrumb-area .breadcrumb-text {
  position: relative;
  padding: 100px 0;
}

.breadcrumb-area .breadcrumb-text,
.breadcrumb-area .breadcrumb-text h2.page-title {
  color: #fff;
}

.breadcrumb-area .breadcrumb-text h2.page-title {
  font-size: 72px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .breadcrumb-area .breadcrumb-text h2.page-title {
    font-size: 60px;
  }
}

@media (max-width: 575px) {
  .breadcrumb-area .breadcrumb-text h2.page-title {
    font-size: 50px;
  }
}

.breadcrumb-area .breadcrumb-text span {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  padding-bottom: 25px;
}

@media (max-width: 767px) {
  .breadcrumb-area .breadcrumb-text span {
    letter-spacing: 2px;
  }
}

@media (max-width: 575px) {
  .breadcrumb-area .breadcrumb-text span {
    font-size: 12px;
  }
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 20px 40px;
  z-index: 2;
}

@media (max-width: 575px) {
  .breadcrumb-area .breadcrumb-text .breadcrumb-nav {
    padding: 15px 20px;
    font-size: 14px;
  }
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li {
  display: inline-block;
  font-weight: 700;
  position: relative;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li,
.breadcrumb-area .breadcrumb-text .breadcrumb-nav li a {
  color: #222;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li a:hover {
  color: #bead8e;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li:not(:first-child) {
  padding-left: 10px;
  margin-left: 10px;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li:not(:first-child)::before {
  position: absolute;
  left: -2px;
  top: 50%;
  -webkit-transform: translateY(-48%);
          transform: translateY(-48%);
  content: '|';
  color: #222;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li.active {
  color: #bead8e;
}

.breadcrumb-area .breadcrumb-text .breadcrumb-nav li.active::before {
  color: #bead8e;
}

/*=======  BANNER CSS  =======*/
.banner-area .banner-content span.promo-tag {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area .banner-content span.promo-tag {
    font-size: 12px;
    letter-spacing: 1px;
  }
}

@media (max-width: 767px) {
  .banner-area .banner-content span.promo-tag {
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.banner-area .banner-content .title {
  font-size: 120px;
  line-height: 1.1;
  font-weight: 400;
  letter-spacing: -6px;
  padding-bottom: 26px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner-area .banner-content .title {
    font-size: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area .banner-content .title {
    font-size: 86px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-area .banner-content .title {
    font-size: 72px;
  }
}

@media (max-width: 575px) {
  .banner-area .banner-content .title {
    font-size: 66px;
  }
}

@media (max-width: 399px) {
  .banner-area .banner-content .title {
    font-size: 52px;
  }
}

.banner-area .banner-content ul li {
  display: inline-block;
}

.banner-area .banner-content ul li:last-child {
  margin-left: 24px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area .banner-content ul li:last-child {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area .banner-content ul li:last-child {
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .banner-area .banner-content ul li:last-child {
    margin-left: 5px;
  }
}

@media (max-width: 399px) {
  .banner-area .banner-content ul li:last-child {
    margin-left: 0;
    margin-top: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .banner-area .banner-content .main-btn {
    padding: 0 35px;
    line-height: 55px;
  }
}

@media (max-width: 575px) {
  .banner-area .banner-content .main-btn {
    padding: 0 25px;
    line-height: 50px;
    letter-spacing: 1px;
  }
}

.banner-area .banner-thumb .single-thumb {
  overflow: hidden;
}

.banner-area .banner-thumb .single-thumb img {
  -webkit-animation: zoomIn 15s linear infinite;
          animation: zoomIn 15s linear infinite;
}

.banner-area.banner-style-one {
  padding-top: 240px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area.banner-style-one {
    padding-top: 200px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.banner-style-one {
    padding-top: 160px;
    padding-bottom: 120px;
  }
}

@media (max-width: 767px) {
  .banner-area.banner-style-one {
    padding-top: 160px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .banner-area.banner-style-one .banner-content {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-area.banner-style-one .banner-content {
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.banner-style-one .banner-content .title {
    font-size: 66px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.banner-style-one .banner-content ul li:last-child {
    margin-left: 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.banner-style-one .banner-content .main-btn {
    padding: 0 20px;
    line-height: 50px;
    letter-spacing: 1px;
  }
}

.banner-area.banner-style-two .single-banner, .banner-area.banner-style-three .single-banner {
  height: 950px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

@media (max-width: 767px) {
  .banner-area.banner-style-two .single-banner, .banner-area.banner-style-three .single-banner {
    height: 700px;
  }
}

.banner-area.banner-style-two .single-banner .banner-bg,
.banner-area.banner-style-two .single-banner .banner-overly, .banner-area.banner-style-three .single-banner .banner-bg,
.banner-area.banner-style-three .single-banner .banner-overly {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-size: cover;
  background-position: center;
}

.banner-area.banner-style-two .single-banner .banner-overly, .banner-area.banner-style-three .single-banner .banner-overly {
  background-color: #222;
  z-index: -1;
  opacity: 0.6;
}

.banner-area.banner-style-two .single-banner .banner-bg, .banner-area.banner-style-three .single-banner .banner-bg {
  -webkit-animation: zoomIn 15s linear infinite;
          animation: zoomIn 15s linear infinite;
}

.banner-area.banner-style-two .banner-content,
.banner-area.banner-style-two .banner-content h1, .banner-area.banner-style-three .banner-content,
.banner-area.banner-style-three .banner-content h1 {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area.banner-style-two .banner-content h1, .banner-area.banner-style-three .banner-content h1 {
    font-size: 90px;
  }
}

.banner-area.banner-style-two .banner-content .main-btn.btn-filled, .banner-area.banner-style-two .banner-content .main-btn.btn-border:hover, .banner-area.banner-style-three .banner-content .main-btn.btn-filled, .banner-area.banner-style-three .banner-content .main-btn.btn-border:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.banner-area.banner-style-two .banner-content .main-btn.btn-filled:hover, .banner-area.banner-style-two .banner-content .main-btn.btn-border, .banner-area.banner-style-three .banner-content .main-btn.btn-filled:hover, .banner-area.banner-style-three .banner-content .main-btn.btn-border {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.banner-area.banner-style-three .single-banner {
  height: auto;
  padding-top: 180px;
  padding-bottom: 125px;
}

.banner-area .slick-arrow {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  height: 50px;
  width: 50px;
  background: #fff;
  line-height: 50px;
  text-align: center;
  font-size: 17px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 9;
}

.banner-area .slick-arrow:hover {
  background-color: #bead8e;
  color: #fff;
}

.banner-area .slick-arrow.next-arrow {
  right: 0;
  left: auto;
}

.banner-area ul.slick-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-51%);
          transform: translateX(-51%);
  bottom: 30px;
}

.banner-area ul.slick-dots li {
  display: inline-block;
  margin: 0 5px;
}

.banner-area ul.slick-dots li button {
  border: none;
  font-size: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  line-height: 0;
  font-size: 0;
}

.banner-area ul.slick-dots li.slick-active button {
  background-color: #bead8e;
}

/*=======  ABOUT CSS  =======*/
.about-section {
  z-index: 1;
}

.about-section .down-arrow-wrap {
  padding-bottom: 125px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .down-arrow-wrap {
    padding-bottom: 90px;
  }
}

@media (max-width: 767px) {
  .about-section .down-arrow-wrap {
    padding-bottom: 90px;
  }
}

.about-section .down-arrow-wrap a.down-arrow {
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 60px;
  background: #bead8e;
  border-radius: 50%;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
  position: relative;
}

.about-section .down-arrow-wrap a.down-arrow::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 1s linear infinite;
          animation: pulse-border-2 1s linear infinite;
}

.about-section .down-arrow-wrap a.down-arrow::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 1.5s linear infinite;
          animation: pulse-border-2 1.5s linear infinite;
}

.about-section .about-title span.title-tag span {
  color: #bead8e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-title h2 {
    font-size: 45px;
  }
}

@media (max-width: 575px) {
  .about-section .about-title h2 {
    font-size: 30px;
  }
}

.about-section .about-features {
  display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 1fr;
  grid-column-gap: 35px;
  margin-top: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-features {
    grid-column-gap: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features {
    grid-column-gap: 15px;
  }
}

@media (max-width: 767px) {
  .about-section .about-features {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 2fr;
    grid-column-gap: 15px;
    margin-top: 60px;
  }
}

@media (max-width: 575px) {
  .about-section .about-features {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 3fr;
  }
}

.about-section .about-features li {
  margin-top: 15px;
}

.about-section .about-features li a {
  background: #fff;
  padding: 55px 0 45px;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(184, 184, 184, 0.1);
          box-shadow: 0px 14px 24px 0px rgba(184, 184, 184, 0.1);
  text-align: center;
  display: block;
  color: #bead8e;
  position: relative;
  z-index: 1;
}

.about-section .about-features li a,
.about-section .about-features li a i,
.about-section .about-features li a span {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.about-section .about-features li a i {
  font-size: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features li a i {
    font-size: 55px;
  }
}

.about-section .about-features li a img {
  max-width: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features li a img {
    max-width: 55px;
  }
}

.about-section .about-features li a .title {
  font-size: 12px;
  color: #111;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  padding-top: 10px;
  display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-features li a .title {
    letter-spacing: 2px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features li a .title {
    letter-spacing: 1px;
  }
}

@media (max-width: 767px) {
  .about-section .about-features li a .title {
    letter-spacing: 1px;
  }
}

.about-section .about-features li a .hover-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  font-size: 75px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.about-section .about-features li a:hover {
  background: #bead8e;
  color: #fff;
}

.about-section .about-features li a:hover .title {
  color: #fff;
}

.about-section .about-features li a:hover .hover-icon {
  visibility: visible;
  opacity: 0.1;
}

.about-section .about-features.masonry-layout {
  margin-top: 0;
}

@media (max-width: 767px) {
  .about-section .about-features.masonry-layout {
    margin-top: 50px;
  }
}

.about-section .about-features.masonry-layout li {
  margin-top: 0;
}

.about-section .about-features.masonry-layout li:nth-child(2) {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features.masonry-layout li:nth-child(2) {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .about-section .about-features.masonry-layout li:nth-child(2) {
    margin-top: 50px;
  }
}

.about-section .about-features.masonry-layout li:nth-child(3) {
  margin-top: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features.masonry-layout li:nth-child(3) {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-section .about-features.masonry-layout li:nth-child(3) {
    margin-top: 100px;
  }
}

@media (max-width: 575px) {
  .about-section .about-features.masonry-layout li:nth-child(3) {
    margin-top: 0;
  }
}

.about-section .about-features.masonry-layout li:nth-child(4) {
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-features.masonry-layout li:nth-child(4) {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .about-section .about-features.masonry-layout li:nth-child(4) {
    margin-top: -50px;
  }
}

@media (max-width: 575px) {
  .about-section .about-features.masonry-layout li:nth-child(4) {
    margin-top: 50px;
  }
}

.about-section .about-features-boxes .single-feature-box {
  background-color: #fff;
  padding: 50px 30px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
          box-shadow: 0px 8px 16px 0px rgba(34, 34, 34, 0.02);
  text-align: center;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-features-boxes .single-feature-box {
    padding: 50px 18px;
  }
}

.about-section .about-features-boxes .single-feature-box .icon {
  font-size: 70px;
  margin-bottom: 20px;
  line-height: 1;
  color: #bead8e;
}

.about-section .about-features-boxes .single-feature-box h4 {
  margin-bottom: 20px;
  font-size: 30px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-features-boxes .single-feature-box h4 {
    font-size: 24px;
  }
}

.about-section .about-features-boxes .single-feature-box.only-bg {
  width: 100%;
  height: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-height: 315px;
  padding: 0;
  background-size: cover;
  background-position: center;
}

.about-section .about-features-boxes .single-feature-box.dark {
  background-color: #222;
}

.about-section .about-features-boxes .single-feature-box.dark,
.about-section .about-features-boxes .single-feature-box.dark h4,
.about-section .about-features-boxes .single-feature-box.dark h4 a {
  color: #fff;
}

.about-section .about-features-boxes .single-feature-box.dark a:hover {
  color: #bead8e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .abour-text {
    padding: 0;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .about-section .abour-text {
    padding: 0;
    margin-top: 50px;
  }
}

.about-section .about-text-box {
  background-color: #fff;
  padding: 30px;
  margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-text-box {
    padding: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-section .about-text-box {
    padding: 50px;
  }
}

@media (max-width: 575px) {
  .about-section .about-text-box {
    padding: 40px 20px;
  }
}

.about-section .about-text-box .about-text {
  font-size: 14px;
  padding-left: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-text-box .about-text {
    padding: 0;
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .about-section .about-text-box .about-text {
    padding: 0;
    margin-top: 50px;
  }
}

.about-section .about-text-box .about-text span {
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.about-section .about-text-box .about-text h3 {
  font-size: 48px;
  margin-bottom: 30px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-text-box .about-text h3 {
    font-size: 38px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .about-section .about-text-box .about-text h3 {
    font-size: 34px;
  }
}

@media (max-width: 575px) {
  .about-section .about-text-box .about-text h3 {
    font-size: 32px;
  }
}

.about-section .about-text-box .about-text .main-btn {
  margin-top: 30px;
}

@media (max-width: 575px) {
  .about-section .about-text-box .about-text .main-btn {
    padding: 0 35px;
  }
}

.about-section .about-right-bottom {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.about-section .about-right-bottom .about-bottom-img {
  position: relative;
}

.about-section .about-right-bottom .about-bottom-img::before {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-size: cover;
  background-position: center;
}

/*=======  ROOM Tab CSS  =======*/
.room-type-section {
  position: relative;
  z-index: 1;
  background-size: 100% 100%;
  background-position: center;
}

.room-type-section::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  z-index: -1;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.room-type-section .room-filter {
  text-align: right;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-type-section .room-filter {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .room-type-section .room-filter {
    margin-top: 30px;
  }
}

.room-type-section .room-filter .nav-item a {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  border: 2px solid transparent;
  padding: 20px 30px;
  cursor: pointer;
  color: #777;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-radius: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-type-section .room-filter .nav-item a {
    padding: 10px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-type-section .room-filter .nav-item a {
    padding: 10px 20px;
  }
}

@media (max-width: 575px) {
  .room-type-section .room-filter .nav-item a {
    padding: 8px 15px;
    font-size: 14px;
  }
}

.room-type-section .room-filter .nav-item a.active, .room-type-section .room-filter .nav-item a:hover {
  color: #111;
  border-color: #e6e6e6;
  background-color: transparent;
}

.room-type-section .room-items .row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.room-type-section .room-items .row > .col,
.room-type-section .room-items .row > [class*='col-'] {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.room-type-section .room-items .room-box {
  width: 100%;
  height: 280px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-top: 15px;
}

.room-type-section .room-items .room-box.extra-height {
  height: 575px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-type-section .room-items .room-box.extra-height {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .room-type-section .room-items .room-box.extra-height {
    height: 280px;
  }
}

.room-type-section .room-items .room-box::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(17, 17, 17, 0.50196) 0%, rgba(17, 17, 17, 0.25) 44%, rgba(17, 17, 17, 0) 100%);
  content: '';
}

.room-type-section .room-items .room-box .room-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.room-type-section .room-items .room-box .room-content {
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 2;
  right: 30px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-type-section .room-items .room-box .room-content {
    left: 15px;
  }
}

.room-type-section .room-items .room-box .room-content h3 {
  font-size: 36px;
}

@media (max-width: 767px) {
  .room-type-section .room-items .room-box .room-content h3 {
    font-size: 26px;
  }
}

.room-type-section .room-items .room-box .room-content h3 a {
  color: #fff;
}

.room-type-section .room-items .room-box .room-content span {
  color: #fff;
  padding-bottom: 15px;
  font-weight: 700;
}

.room-type-section .room-items .room-box .room-content span i {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .room-type-section .room-items .room-box .room-content span {
    font-size: 14px;
  }
}

.room-type-section .room-items .room-box .room-link {
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 60px;
  height: 60px;
  position: absolute;
  right: 15px;
  bottom: 0;
  border-radius: 50%;
  z-index: 3;
  color: #fff;
  font-size: 18px;
  line-height: 60px;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 767px) {
  .room-type-section .room-items .room-box .room-link {
    height: 40px;
    width: 40px;
    text-align: center;
    right: 10px;
    line-height: 40px;
  }
}

.room-type-section .room-items .room-box .room-link:hover {
  background-color: #fff;
  color: #111;
}

.room-type-section .room-items .room-box.extra-wide .room-link {
  right: 40px;
}

.room-type-section .room-items .room-box:hover .room-link {
  opacity: 1;
  visibility: visible;
  bottom: 30px;
}

.room-type-section .room-items .room-box:hover .room-bg {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*=======  CALL TO ACTIONS  =======*/
.cta-section .cta-inner {
  padding: 100px 0;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.cta-section .cta-inner .cta-text p {
  font-size: 20px;
  line-height: 1.6;
  color: #a08d69;
}

.cta-section .cta-inner .cta-text a.main-btn.btn-filled {
  background-color: #111;
  color: #fff;
  margin-top: 40px;
  border-color: #111;
}

.cta-section .cta-inner .cta-text a.main-btn.btn-filled:hover {
  background-color: #bead8e;
  border-color: #bead8e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cta-section .cta-inner .cta-text {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .cta-section .cta-inner .cta-text {
    margin-top: 60px;
  }
}

.cta-section .cta-inner .cta-features .single-feature {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: 120px 1fr;
      grid-template-columns: 120px 1fr;
  margin-bottom: 45px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section .cta-inner .cta-features .single-feature {
    -ms-grid-columns: 100px 1fr;
        grid-template-columns: 100px 1fr;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .cta-section .cta-inner .cta-features .single-feature {
    -ms-grid-columns: 100px 1fr;
        grid-template-columns: 100px 1fr;
  }
}

@media (max-width: 575px) {
  .cta-section .cta-inner .cta-features .single-feature {
    display: block;
  }
}

.cta-section .cta-inner .cta-features .single-feature .icon {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(190, 173, 142, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(190, 173, 142, 0.2);
  text-align: center;
  line-height: 120px;
  font-size: 60px;
  color: #bead8e;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section .cta-inner .cta-features .single-feature .icon {
    height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .cta-section .cta-inner .cta-features .single-feature .icon {
    height: 100px;
    width: 100px;
    line-height: 100px;
    font-size: 40px;
  }
}

@media (max-width: 575px) {
  .cta-section .cta-inner .cta-features .single-feature .icon {
    margin-bottom: 30px;
  }
}

.cta-section .cta-inner .cta-features .single-feature .cta-desc {
  position: relative;
  padding-left: 40px;
  z-index: 1;
  font-size: 14px;
}

@media (max-width: 575px) {
  .cta-section .cta-inner .cta-features .single-feature .cta-desc {
    padding-left: 0;
  }
}

.cta-section .cta-inner .cta-features .single-feature .cta-desc h3 {
  font-size: 30px;
  color: #111;
  margin-bottom: 20px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cta-section .cta-inner .cta-features .single-feature .cta-desc h3 {
    font-size: 26px;
  }
}

.cta-section .cta-inner .cta-features .single-feature .cta-desc .count {
  position: absolute;
  font-size: 120px;
  font-weight: 700;
  left: -32px;
  top: 0;
  line-height: 1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #111;
  z-index: -1;
  color: transparent;
  opacity: 0.1;
}

@media (max-width: 575px) {
  .cta-section .cta-inner .cta-features .single-feature .cta-desc .count {
    left: 0;
    top: -75px;
  }
}

.cta-section .cta-inner .cta-features .single-feature:hover .icon {
  background-color: #bead8e;
  color: #fff;
}

.cta-section .cta-inner .cta-features .single-feature:last-child {
  margin-bottom: 0;
}

.cta-section .cta-inner::after {
  content: '';
  position: absolute;
  left: 35px;
  right: 35px;
  bottom: -40px;
  background-color: #bead8e;
  height: 20px;
}

.cta-section .cta-inner::before {
  content: '\f127';
  font-family: 'Flaticon';
  left: 0;
  bottom: 0;
  line-height: 1;
  color: #f6f6f6;
  font-size: 350px;
  position: absolute;
}

/*=======  Call TO Action Two  =======*/
.room-gallery-cta {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center;
  padding: 120px 0;
  background-attachment: fixed;
}

.room-gallery-cta::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #111;
  opacity: 0.7;
  content: '';
}

.room-gallery-cta ul li {
  display: inline-block;
  margin: 0px 10px;
}

@media (max-width: 767px) {
  .room-gallery-cta ul li {
    margin: 0 5px;
  }
}

@media (max-width: 767px) {
  .room-gallery-cta ul li .main-btn {
    padding: 0 25px;
    letter-spacing: 1px;
  }
}

@media (max-width: 399px) {
  .room-gallery-cta ul li .main-btn {
    padding: 0 15px;
    letter-spacing: 1px;
    font-size: 12px;
  }
}

.room-gallery-cta ul li .main-btn.btn-filled, .room-gallery-cta ul li .main-btn.btn-border:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.room-gallery-cta ul li .main-btn.btn-filled:hover, .room-gallery-cta ul li .main-btn.btn-border {
  border-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.room-gallery-cta .cta-text h2 {
  font-size: 80px;
  line-height: 1.125;
  letter-spacing: -3px;
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .room-gallery-cta .cta-text h2 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-gallery-cta .cta-text h2 {
    font-size: 60px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-gallery-cta .cta-text h2 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gallery-cta .cta-text h2 {
    font-size: 42px;
  }
}

@media (max-width: 575px) {
  .room-gallery-cta .cta-text h2 {
    font-size: 32px;
  }
}

@media (max-width: 399px) {
  .room-gallery-cta .cta-text h2 {
    font-size: 28px;
  }
}

.room-gallery-cta .cta-text span {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 3px;
  padding-bottom: 20px;
  color: #bead8e;
}

.room-gallery-cta .rotate-images img {
  position: absolute;
  z-index: 1;
  bottom: 0;
  -webkit-transform: rotate(-37deg);
          transform: rotate(-37deg);
  left: 3%;
  z-index: -1;
}

@media (max-width: 767px) {
  .room-gallery-cta .rotate-images img {
    max-width: 150px;
  }
}

@media (max-width: 399px) {
  .room-gallery-cta .rotate-images img {
    max-width: 100px;
  }
}

.room-gallery-cta .rotate-images img.rotate-image-one {
  left: 8%;
  top: 0;
  bottom: auto;
  max-width: 260px;
  -webkit-transform: rotate(-37deg);
          transform: rotate(-37deg);
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .room-gallery-cta .rotate-images img.rotate-image-one {
    left: 2%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-gallery-cta .rotate-images img.rotate-image-one {
    left: 2%;
    max-width: 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-gallery-cta .rotate-images img.rotate-image-one {
    left: 1%;
    max-width: 180px;
  }
}

@media (max-width: 767px) {
  .room-gallery-cta .rotate-images img.rotate-image-one {
    left: 1%;
    max-width: 150px;
  }
}

@media (max-width: 399px) {
  .room-gallery-cta .rotate-images img.rotate-image-one {
    max-width: 100px;
  }
}

.room-gallery-cta .rotate-images img.rotate-image-three {
  right: 5%;
  left: auto;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .room-gallery-cta .rotate-images img.rotate-image-three {
    left: auto;
    right: 2%;
    max-width: 250px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-gallery-cta .rotate-images img.rotate-image-three {
    left: auto;
    right: 2%;
    max-width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-gallery-cta .rotate-images img.rotate-image-three {
    left: auto;
    right: 1%;
    max-width: 200px;
  }
}

@media (max-width: 767px) {
  .room-gallery-cta .rotate-images img.rotate-image-three {
    left: auto;
    right: 1%;
    max-width: 150px;
  }
}

@media (max-width: 399px) {
  .room-gallery-cta .rotate-images img.rotate-image-three {
    max-width: 100px;
  }
}

/*=======  TEXT BLOCK  =======*/
.text-block.with-bg {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.text-block.with-bg::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #fff;
  z-index: -1;
  opacity: 0.94;
}

.text-block.bg-black p,
.text-block.bg-black .section-title h2 {
  color: #fff;
}

.text-block.bg-black .section-title .title-tag {
  color: #bead8e;
}

.text-block.bg-black .main-btn.btn-filled {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.text-block.bg-black .main-btn.btn-filled:hover {
  color: #fff;
}

.text-block.with-pattern {
  position: relative;
  z-index: 1;
}

.text-block.with-pattern .pattern-wrap {
  position: absolute;
  background: #bead8e;
  right: 0;
  height: 100%;
  z-index: -1;
  width: 30%;
  top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-block.with-pattern .pattern-wrap {
    width: 100%;
    height: 320px;
  }
}

@media (max-width: 767px) {
  .text-block.with-pattern .pattern-wrap {
    width: 100%;
    height: 320px;
  }
}

.text-block.with-pattern .pattern-wrap .pattern {
  position: relative;
  width: 100%;
  height: 100%;
}

.text-block.with-pattern .pattern-wrap .pattern::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  content: '';
  background-size: cover;
  background-position: right;
}

@media (max-width: 575px) {
  .text-block .block-text p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .text-block .mb-small {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .text-block .mb-small {
    margin-bottom: 50px;
  }
}

/*=======  Video  =======*/
.video-wrap {
  width: 100%;
  height: 480px;
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 575px) {
  .video-wrap {
    height: 350px;
  }
}

.video-wrap .popup-video {
  border-radius: 50%;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
  width: 60px;
  height: 60px;
  line-height: 65px;
  background-color: #fff;
  text-align: center;
  font-size: 16px;
  color: #111;
  position: relative;
  cursor: pointer;
}

.video-wrap .popup-video::before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 1s linear infinite;
          animation: pulse-border-2 1s linear infinite;
}

.video-wrap .popup-video::after {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 50%;
  -webkit-animation: pulse-border-2 1.5s linear infinite;
          animation: pulse-border-2 1.5s linear infinite;
}

.video-wrap.video-wrap-two {
  height: 460px;
}
.video-modal{
  width: 100%;
  height: 500px;
  padding: 0;
}
.video-modal iframe{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border: none;
}
.video-modal button{
  top:-30px;
}
@media (max-width: 575px) {
  .video-wrap.video-wrap-two {
    height: 350px;
  }
}

.video-wrap.video-wrap-two .popup-video {
  height: 120px;
  width: 120px;
  line-height: 120px;
  font-size: 20px;
  -webkit-box-shadow: none;
          box-shadow: none;
          cursor: pointer;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video-wrap.video-wrap-two .popup-video {
    height: 100px;
    width: 100px;
    line-height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-wrap.video-wrap-two .popup-video {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

@media (max-width: 767px) {
  .video-wrap.video-wrap-two .popup-video {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }
}

.video-wrap.full-section {
  height: 700px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-wrap.full-section {
    height: 550px;
  }
}

@media (max-width: 767px) {
  .video-wrap.full-section {
    height: 380px;
  }
}
iframe{
  border: none;
}
.video-wrap.full-section .popup-video {
  height: auto;
  width: auto;
  background-color: transparent;
  cursor: pointer;
}

.video-wrap.full-section .popup-video::before, .video-wrap.full-section .popup-video::after {
  display: none;
}

.video-wrap.full-section .popup-video img {
  max-width: 115px;
  max-height: 115px;
}

@media (max-width: 767px) {
  .video-wrap.full-section .popup-video img {
    max-width: 80px;
    max-height: 80px;
  }
}

.video-wrap.video-about {
  height: 650px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video-wrap.video-about {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .video-wrap.video-about {
    height: 300px;
  }
}

/*=======  Testimonial  =======*/
.testimonial-box {
  background-color: #fff;
  text-align: center;
  padding: 50px 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box {
    padding: 50px 20px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-box {
    padding: 50px 20px;
    font-size: 14px;
  }
}

.testimonial-box .client-img {
  max-width: 150px;
  max-height: 150px;
  margin: auto;
  position: relative;
}

.testimonial-box .client-img img {
  max-width: 150px;
  max-height: 150px;
  border-radius: 50%;
}

.testimonial-box .client-img .check {
  position: absolute;
  right: 0;
  top: 0;
  border: 4px solid #fff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #bead8e;
  text-align: center;
  line-height: 50px;
  color: #fff;
}

.testimonial-box h3 {
  font-size: 30px;
  margin-bottom: 15px;
  margin-top: 35px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box h3 {
    font-size: 26px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .testimonial-box h3 {
    font-size: 22px;
  }
}

.testimonial-box .clinet-post {
  font-weight: bold;
  text-transform: uppercase;
  color: #bead8e;
  margin-bottom: 30px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box .clinet-post {
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-box .clinet-post {
    font-size: 12px;
  }
}

.testimonial-box:hover {
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.2);
}

.testimonial-slider ul.slick-dots {
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.testimonial-slider ul.slick-dots li {
  display: inline-block;
  cursor: pointer;
}

.testimonial-slider ul.slick-dots li button {
  font-size: 0;
  border: 0;
  background: #e7e7e7;
  width: 16px;
  height: 4px;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.testimonial-slider ul.slick-dots li.slick-active button {
  width: 26px;
  background-color: #bead8e;
}

/*=======  Latest Post   =======*/
.latest-news .latest-post-box .post-img {
  height: 260px;
  width: 100%;
  background-size: cover;
  background-position: center;
}

@media (max-width: 767px) {
  .latest-news .latest-post-box .post-img {
    height: 220px;
  }
}

.latest-news .latest-post-box .post-desc {
  background-color: #fff;
  padding: 40px 35px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .latest-news .latest-post-box .post-desc {
    padding: 35px 25px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest-news .latest-post-box .post-desc {
    padding: 35px 18px;
    font-size: 14px;
  }
}

@media (max-width: 399px) {
  .latest-news .latest-post-box .post-desc {
    padding: 35px 25px;
    font-size: 14px;
  }
}

.latest-news .latest-post-box .post-desc .post-meta {
  margin-bottom: 20px;
}

.latest-news .latest-post-box .post-desc .post-meta li {
  display: inline-block;
  margin-right: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .latest-news .latest-post-box .post-desc .post-meta li {
    margin-right: 5px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .latest-news .latest-post-box .post-desc .post-meta li {
    margin-right: 10px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest-news .latest-post-box .post-desc .post-meta li {
    margin-right: 5px;
    font-size: 12px;
  }
}

@media (max-width: 399px) {
  .latest-news .latest-post-box .post-desc .post-meta li {
    margin-right: 5px;
    font-size: 12px;
  }
}

.latest-news .latest-post-box .post-desc .post-meta li a {
  font-weight: 500;
  color: #777;
}

.latest-news .latest-post-box .post-desc .post-meta li a i {
  margin-right: 10px;
}

.latest-news .latest-post-box .post-desc .post-meta li:last-child {
  margin-right: 0;
}

.latest-news .latest-post-box .post-desc h4 {
  font-size: 24px;
  color: #111;
  margin-bottom: 25px;
  letter-spacing: -1px;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .latest-news .latest-post-box .post-desc h4 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest-news .latest-post-box .post-desc h4 {
    font-size: 20px;
  }
}

@media (max-width: 399px) {
  .latest-news .latest-post-box .post-desc h4 {
    font-size: 20px;
  }
}

/*=======  Core Feature  =======*/
.features-loop .feature-box {
  padding: 60px 50px;
  background-color: #fff;
  font-size: 14px;
  background-color: #fff;
  border: 2px solid #f8f8f8;
  position: relative;
  margin-top: 30px;
  /*=======  Dark Style  =======*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .features-loop .feature-box {
    padding: 50px 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .features-loop .feature-box {
    padding: 50px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .features-loop .feature-box {
    padding: 35px 25px;
  }
}

@media (max-width: 575px) {
  .features-loop .feature-box {
    padding: 50px 30px;
  }
}

.features-loop .feature-box .icon {
  margin-bottom: 30px;
  font-size: 70px;
  color: #bead8e;
  line-height: 1;
}

.features-loop .feature-box:hover .icon i {
  -webkit-animation: hvr-buzz-out 0.75s linear 1;
          animation: hvr-buzz-out 0.75s linear 1;
}

.features-loop .feature-box h3 {
  font-size: 30px;
  margin-bottom: 30px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .features-loop .feature-box h3 {
    font-size: 24px;
  }
}

.features-loop .feature-box .count {
  line-height: 1;
  position: absolute;
  right: 0;
  top: -10px;
  font-size: 120px;
  color: #f8f8f8;
}

.features-loop .feature-box.with-hover-img .hover-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: visible;
  opacity: 1;
}

.features-loop .feature-box.with-hover-img:hover .hover-img {
  opacity: 0;
  visibility: hidden;
}

.features-loop .feature-box.dark-box {
  background-color: #292929;
  border: none;
}

.features-loop .feature-box.dark-box,
.features-loop .feature-box.dark-box h3 a {
  color: #fff;
}

.features-loop .feature-box.dark-box .count {
  color: #222;
}

/*=======  Room Gird  =======*/
.room-gird-loop .room-box .room-img-wrap {
  overflow: hidden;
  width: 100%;
  height: 240px;
}

.room-gird-loop .room-box .room-img-wrap .room-img {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background-size: cover;
  background-position: center;
}

.room-gird-loop .room-box:hover .room-img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.room-gird-loop .room-box .room-desc {
  background-color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 50px 35px 30px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-gird-loop .room-box .room-desc {
    padding: 50px 25px 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gird-loop .room-box .room-desc {
    padding: 50px 20px 30px;
  }
}

.room-gird-loop .room-box .room-desc .icons {
  background-color: #fff;
  padding: 15px;
  position: absolute;
  left: 25px;
  right: 25px;
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
          box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gird-loop .room-box .room-desc .icons {
    left: 12px;
    right: 12px;
    padding: 15px 5px;
  }
}

.room-gird-loop .room-box .room-desc .icons li {
  display: inline-block;
  margin: 0px 10px;
  color: #222;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-gird-loop .room-box .room-desc .icons li {
    margin: 0 5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-gird-loop .room-box .room-desc .icons li {
    margin: 0 8px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gird-loop .room-box .room-desc .icons li {
    margin: 0 5px;
  }
}

@media (max-width: 399px) {
  .room-gird-loop .room-box .room-desc .icons li {
    margin: 0 5px;
  }
}

.room-gird-loop .room-box .room-desc h4 {
  font-size: 24px;
  letter-spacing: -1px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gird-loop .room-box .room-desc h4 {
    font-size: 20px;
  }
}

.room-gird-loop .room-box .room-desc span.price,
.room-gird-loop .room-box .room-desc a.book-btn {
  font-size: 16px;
  font-weight: 700;
  color: #bead8e;
  margin-top: 25px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gird-loop .room-box .room-desc span.price,
  .room-gird-loop .room-box .room-desc a.book-btn {
    font-size: 14px;
  }
}

.room-gird-loop .room-box .room-desc a.book-btn {
  color: #bdbdbd;
  margin-left: 20px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-gird-loop .room-box .room-desc a.book-btn {
    margin-left: 10px;
  }
}

.room-gird-loop .room-box .room-desc a.book-btn:hover {
  color: #bead8e;
}

/*=======  Room List  =======*/
.room-list-loop .room-box .room-img-wrap {
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 310px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-list-loop .room-box .room-img-wrap {
    min-height: 350px;
  }
}

@media (max-width: 575px) {
  .room-list-loop .room-box .room-img-wrap {
    min-height: 250px;
  }
}

.room-list-loop .room-box .room-img-wrap .room-img {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-size: cover;
  background-position: center;
}

.room-list-loop .room-box:hover .room-img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.room-list-loop .room-box .room-desc {
  background-color: #fff;
  font-size: 14px;
  padding: 50px 20px 50px 40px;
  position: relative;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-list-loop .room-box .room-desc {
    padding: 50px 20px 50px 25px;
  }
}

@media (max-width: 575px) {
  .room-list-loop .room-box .room-desc {
    padding: 50px 30px;
  }
}

@media (max-width: 399px) {
  .room-list-loop .room-box .room-desc {
    padding: 50px 25px;
  }
}

.room-list-loop .room-box .room-desc .icons {
  background-color: #fff;
  padding: 15px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
          box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
  display: inline-block;
  margin-bottom: 25px;
}

.room-list-loop .room-box .room-desc .icons li {
  display: inline-block;
  margin: 0px 10px;
  color: #222;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-list-loop .room-box .room-desc .icons li {
    margin: 0 8px;
  }
}

@media (max-width: 575px) {
  .room-list-loop .room-box .room-desc .icons li {
    margin: 0 5px;
  }
}

.room-list-loop .room-box .room-desc h4 {
  font-size: 24px;
  letter-spacing: -1px;
  margin-bottom: 15px;
}

.room-list-loop .room-box .room-desc a.book-btn {
  font-size: 16px;
  font-weight: 700;
  color: #bdbdbd;
  position: relative;
  margin-top: 20px;
  padding-top: 20px;
}

.room-list-loop .room-box .room-desc a.book-btn:hover {
  color: #bead8e;
}

.room-list-loop .room-box .room-desc a.book-btn::before {
  position: absolute;
  content: '';
  left: 50%;
  top: 0;
  height: 2px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 60px;
  background-color: #efefef;
}

.room-list-loop .room-box .room-desc .price {
  font-size: 20px;
  font-weight: 550;
  color: #bead8e;
  line-height: 1;
  display: block;
}

@media (max-width: 575px) {
  .room-list-loop .room-box .room-desc .price {
    margin-top: 30px;
  }
}

.room-list-loop .room-box .room-desc .price span {
  font-size: 16px;
}

/*=======  Room Slider  =======*/
.room-slider {
  position: relative;
  z-index: 1;
  /*=======  Room Slider One  =======*/
  /*=======  Room Slider Two  =======*/
}

.room-slider .rooms-slider-one.row {
  margin-left: -20px;
  margin-right: -20px;
}

.room-slider .rooms-slider-one.row > .col {
  padding-left: 20px;
  padding-right: 20px;
}

.room-slider .rooms-slider-one .slider-img {
  width: 100%;
  height: 580px;
  background-size: cover;
  background-position: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-slider .rooms-slider-one .slider-img {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-one .slider-img {
    height: 450px;
  }
}

@media (max-width: 575px) {
  .room-slider .rooms-slider-one .slider-img {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-content-wrap {
    margin-bottom: 100px;
  }
}

.room-slider .rooms-content-wrap .room-content-box {
  background-color: #222;
  color: #d4d4d4;
  font-size: 14px;
  padding: 60px 50px;
  position: relative;
  z-index: 2;
  margin-top: -495px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-slider .rooms-content-wrap .room-content-box {
    margin-top: -445px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-content-wrap .room-content-box {
    margin-top: -100px;
    padding: 50px 30px;
  }
}

@media (max-width: 575px) {
  .room-slider .rooms-content-wrap .room-content-box {
    margin-top: -50px;
    padding: 50px 30px;
  }
}

.room-slider .rooms-content-wrap .room-content-box .slider-count {
  position: absolute;
  right: 50px;
  font-size: 14px;
  font-weight: 700;
  top: 90px;
  color: #4c4c4c;
  z-index: 3;
  letter-spacing: 2px;
}

.room-slider .rooms-content-wrap .room-content-box .slider-count .current {
  color: #bead8e;
}

.room-slider .rooms-content-wrap .room-content-box .slider-count-big {
  position: absolute;
  bottom: -30px;
  right: 0;
  line-height: 1;
  font-size: 200px;
  z-index: -1;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
  opacity: 0.1;
}

@media (max-width: 767px) {
  .room-slider .rooms-content-wrap .room-content-box .slider-count-big {
    font-size: 150px;
  }
}

.room-slider .rooms-content-wrap .room-content-box h3 {
  font-size: 36px;
  color: #fff;
  margin-bottom: 20px;
}

.room-slider .rooms-content-wrap .room-content-box h3,
.room-slider .rooms-content-wrap .room-content-box h3 a {
  color: #fff;
}

.room-slider .rooms-content-wrap .room-content-box .icon {
  line-height: 1;
  color: #bead8e;
  margin-bottom: 40px;
  font-size: 70px;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider {
  padding-bottom: 45px;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider .single-content {
  overflow: hidden;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots {
  position: absolute;
  left: 0;
  bottom: 0;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li {
  display: inline-block;
  cursor: pointer;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li button {
  font-size: 0;
  border: 0;
  background: #434343;
  width: 16px;
  height: 4px;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.room-slider .rooms-content-wrap .room-content-box .room-content-slider ul.slick-dots li.slick-active button {
  width: 26px;
  background-color: #bead8e;
}

.room-slider .rooms-slider-two .single-rooms-box {
  position: relative;
  margin-bottom: 130px;
  margin-top: 25px;
}

.room-slider .rooms-slider-two .single-rooms-box .room-img {
  overflow: hidden;
  width: 100%;
  height: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-img {
    height: 420px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-img {
    height: 350px;
  }
}

.room-slider .rooms-slider-two .single-rooms-box .room-img .img {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.room-slider .rooms-slider-two .single-rooms-box:hover .img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.room-slider .rooms-slider-two .single-rooms-box .icons {
  background-color: #fff;
  padding: 15px;
  position: absolute;
  top: -25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
          box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
  visibility: visible;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.room-slider .rooms-slider-two .single-rooms-box .icons li {
  display: inline-block;
  margin: 0px 10px;
  color: #222;
}

@media (max-width: 575px) {
  .room-slider .rooms-slider-two .single-rooms-box .icons li {
    margin: 0px 5px;
  }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: -115px;
  background-color: #fff;
  padding: 40px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
          box-shadow: 0px 8px 16px 0px rgba(138, 138, 138, 0.04);
  font-size: 14px;
  visibility: visible;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc {
    left: 20px;
    right: 20px;
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc {
    left: 20px;
    right: 20px;
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc {
    left: 10px;
    right: 10px;
    padding: 25px;
  }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
  font-size: 36px;
  margin-bottom: 10px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc h3 {
    font-size: 26px;
  }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
  text-align: right;
  font-size: 40px;
  color: #bead8e;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
    font-size: 32px;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
    font-size: 28px;
  }
}

@media (max-width: 575px) {
  .room-slider .rooms-slider-two .single-rooms-box .room-desc .price {
    text-align: left;
    margin-top: 10px;
  }
}

.room-slider .rooms-slider-two .single-rooms-box .room-desc .price span {
  font-size: 16px;
}

.room-slider .rooms-slider-two .slick-current .single-rooms-box .room-desc,
.room-slider .rooms-slider-two .slick-current .single-rooms-box .icons {
  visibility: visible;
  opacity: 1;
}

.room-slider .rooms-slider-two .slick-arrow {
  height: 80px;
  width: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 20px;
  color: #111;
  background: #fff;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: absolute;
  top: 40%;
  z-index: 2;
  left: 22%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-slider .rooms-slider-two .slick-arrow {
    left: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-slider .rooms-slider-two .slick-arrow {
    left: 5%;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-two .slick-arrow {
    left: 2%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
  }
}

.room-slider .rooms-slider-two .slick-arrow.next-arrow {
  margin-left: 20px;
  left: auto;
  right: 22%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-slider .rooms-slider-two .slick-arrow.next-arrow {
    left: auto;
    right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-slider .rooms-slider-two .slick-arrow.next-arrow {
    left: auto;
    right: 5%;
  }
}

@media (max-width: 767px) {
  .room-slider .rooms-slider-two .slick-arrow.next-arrow {
    left: auto;
    right: 2%;
  }
}

.room-slider .rooms-slider-two .slick-arrow:hover, .room-slider .rooms-slider-two .slick-arrow.next-arrow {
  background-color: #bead8e;
  color: #fff;
  -webkit-box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
          box-shadow: 0px 14px 24px 0px rgba(190, 173, 142, 0.3);
}

/*=======  Room Details  =======*/
.room-details .deatils-box {
  font-family: "Roboto", sans-serif;
}

.room-details .deatils-box .title-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 40px 50px;
  background-color: #fff;
  margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .deatils-box .title-wrap {
    padding: 40px 30px;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-details .deatils-box .title-wrap {
    padding: 30px 20px;
    margin-right: 20px;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .title-wrap {
    padding: 30px;
    margin-right: 20px;
    display: block;
  }
}

.room-details .deatils-box .title-wrap .title .room-cat {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 3px;
}

.room-details .deatils-box .title-wrap .title h2 {
  font-size: 48px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .deatils-box .title-wrap .title h2 {
    font-size: 42px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-details .deatils-box .title-wrap .title h2 {
    font-size: 32px;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .title-wrap .title h2 {
    font-size: 28px;
  }
}

.room-details .deatils-box .title-wrap .price {
  font-size: 40px;
  color: #bead8e;
  font-weight: 700;
  font-family: "Archivo", sans-serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .deatils-box .title-wrap .price {
    font-size: 35px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-details .deatils-box .title-wrap .price {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .title-wrap .price {
    font-size: 24px;
    margin-top: 15px;
  }
}

.room-details .deatils-box .title-wrap .price span {
  font-size: 16px;
}

.room-details .deatils-box .thumb {
  margin-top: -90px;
  position: relative;
  z-index: -1;
  margin-bottom: 60px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-details .deatils-box .thumb {
    margin-top: -65px;
  }
}

.room-details .deatils-box h3.subtitle {
  font-size: 30px;
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f1f1f1;
}

.room-details .deatils-box .room-fearures {
  background-color: #fff;
  padding: 50px 50px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .deatils-box .room-fearures {
    padding: 50px 35px 30px;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .room-fearures {
    padding: 50px 30px 30px;
  }
}

.room-details .deatils-box .room-fearures .room-fearures-list li {
  width: 33.33%;
  float: left;
  margin-bottom: 20px;
  padding-left: 40px;
  position: relative;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .room-details .deatils-box .room-fearures .room-fearures-list li {
    width: 50%;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .room-fearures .room-fearures-list li {
    width: 100%;
  }
}

.room-details .deatils-box .room-fearures .room-fearures-list li i {
  color: #bead8e;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.room-details .deatils-box .room-rules {
  background-color: #fff;
  padding: 50px 50px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .deatils-box .room-rules {
    padding: 50px 35px 30px;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .room-rules {
    padding: 50px 30px 30px;
  }
}

.room-details .deatils-box .room-rules .room-rules-list li {
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
}

.room-details .deatils-box .room-rules .room-rules-list li::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #bead8e;
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
  content: '\f00c';
}

.room-details .deatils-box .cancellation-box {
  background-color: #fff;
  padding: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .deatils-box .cancellation-box {
    padding: 50px 35px;
  }
}

@media (max-width: 575px) {
  .room-details .deatils-box .cancellation-box {
    padding: 50px 30px;
  }
}

.room-details .deatils-box .cancellation-box strong {
  color: #222;
}

.room-details .deatils-box .room-map {
  width: 100%;
  height: 390px;
  background-color: #fff;
}

@media (max-width: 575px) {
  .room-details .deatils-box .room-map {
    height: 320px;
  }
}

.room-details .deatils-box .room-map iframe {
  width: 100%;
  height: 100%;
}

.room-details .deatils-box .related-room h3.subtitle {
  border-color: #dddddd;
}

.room-details .room-booking-form {
  background-color: #fff;
  padding: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .room-details .room-booking-form {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .room-details .room-booking-form {
    margin-top: 30px;
  }
}

.room-details .room-booking-form input,
.room-details .room-booking-form .nice-select,
.room-details .room-booking-form select {
  background-color: #f8f8f8;
}

.room-details .room-booking-form .main-btn {
  height: 60px;
  line-height: 60px;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 100%;
  padding: 0;
}

.room-details .room-booking-form .title {
  font-weight: 700;
  font-family: "Archivo", sans-serif;
  margin-bottom: 30px;
  padding-left: 60px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .room-booking-form .title {
    padding-left: 50px;
  }
}

@media (max-width: 399px) {
  .room-details .room-booking-form .title {
    padding-left: 50px;
  }
}

.room-details .room-booking-form .title::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  height: 2px;
  background-color: #bead8e;
  content: '';
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .room-details .room-booking-form .title::before {
    width: 30px;
  }
}

@media (max-width: 399px) {
  .room-details .room-booking-form .title::before {
    width: 30px;
  }
}

/*=======  COUNTER CSS  =======*/
.counter-section .counter-box {
  margin-bottom: 50px;
}

@media (max-width: 575px) {
  .counter-section .counter-box {
    font-size: 12px;
  }
}

.counter-section .counter-box .icon {
  margin-bottom: 30px;
}

.counter-section .counter-box .icon img {
  max-width: 100px;
}

.counter-section .counter-box,
.counter-section .counter-box h4 {
  color: #fff;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

.counter-section .counter-box h4 {
  font-size: 60px;
  word-spacing: 0;
}

.counter-section .counter-box h4 .plus-icon {
  font-size: 16px;
}

@media (max-width: 575px) {
  .counter-section .counter-box h4 {
    font-size: 38px;
  }
}

.counter-section .counter-box .title {
  color: #999999;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-section .counter-box.box-layout {
    font-size: 12px;
  }
}

.counter-section .counter-box.counter-box-two {
  background-color: #fff;
  padding: 30px 40px 30px 120px;
  position: relative;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-section .counter-box.counter-box-two {
    padding: 30px 20px 30px 115px;
  }
}

@media (max-width: 767px) {
  .counter-section .counter-box.counter-box-two {
    padding: 110px 25px 30px;
  }
}

.counter-section .counter-box.counter-box-two,
.counter-section .counter-box.counter-box-two h4 {
  font-weight: 400;
  color: #777;
}

.counter-section .counter-box.counter-box-two .icon {
  position: absolute;
  top: 40px;
  left: 40px;
  font-size: 60px;
  color: #bead8e;
  margin: 0;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .counter-section .counter-box.counter-box-two .icon {
    left: 30px;
  }
}

@media (max-width: 767px) {
  .counter-section .counter-box.counter-box-two .icon {
    top: 30px;
    left: 25px;
  }
}

.counter-section .counter-box.counter-box-two h4 {
  color: #222;
  letter-spacing: -1px;
  font-family: "Old Standard TT", serif;
}

/*=======  Our Menu  =======*/
.menu-area {
  position: relative;
  z-index: 1;
}

.menu-area.menu-nagative-gallery {
  padding-top: 115px;
  margin-bottom: 305px;
  padding-bottom: 285px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-area.menu-nagative-gallery {
    margin-bottom: 230px;
    padding-bottom: 220px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu-area.menu-nagative-gallery {
    margin-bottom: 200px;
    padding-bottom: 200px;
  }
}

@media (max-width: 767px) {
  .menu-area.menu-nagative-gallery {
    margin-bottom: 200px;
    padding-bottom: 200px;
  }
}

@media (max-width: 575px) {
  .menu-area.menu-nagative-gallery {
    margin-bottom: 230px;
    padding-bottom: 240px;
  }
}

.menu-area.menu-nagative-gallery .gallery-wrap {
  position: absolute;
  bottom: -190px;
  width: 100%;
  left: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-area.menu-nagative-gallery .gallery-wrap {
    bottom: -130px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu-area.menu-nagative-gallery .gallery-wrap {
    bottom: -100px;
  }
}

@media (max-width: 767px) {
  .menu-area.menu-nagative-gallery .gallery-wrap {
    bottom: -100px;
  }
}

@media (max-width: 575px) {
  .menu-area.menu-nagative-gallery .gallery-wrap {
    bottom: -150px;
  }
}

.menu-area.menu-with-shape .shape-one {
  position: absolute;
  left: 2%;
  top: -70px;
  max-width: 250px;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-area.menu-with-shape .shape-one {
    max-width: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu-area.menu-with-shape .shape-one {
    max-width: 180px;
  }
}

@media (max-width: 767px) {
  .menu-area.menu-with-shape .shape-one {
    display: none;
  }
}

.menu-area.menu-with-shape .shape-two {
  position: absolute;
  right: 3%;
  bottom: 160px;
  z-index: -1;
  max-width: 250px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .menu-area.menu-with-shape .shape-two {
    max-width: 180px;
  }
}

.menu-area .menu-loop .single-menu-box {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 20px;
  padding-left: 120px;
  position: relative;
}

@media (max-width: 575px) {
  .menu-area .menu-loop .single-menu-box {
    padding-left: 0;
    padding-top: 140px;
  }
}

.menu-area .menu-loop .single-menu-box .menu-desc {
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  font-size: 14px;
  color: #222;
  padding: 20px 85px 20px 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-area .menu-loop .single-menu-box .menu-desc {
    padding: 20px 50px 20px 30px;
  }
}

@media (max-width: 399px) {
  .menu-area .menu-loop .single-menu-box .menu-desc {
    padding: 20px 50px 20px 30px;
  }
}

.menu-area .menu-loop .single-menu-box .menu-desc h4 {
  font-size: 24px;
  letter-spacing: -1px;
  margin-bottom: 8px;
}

.menu-area .menu-loop .single-menu-box .menu-desc .menu-link {
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 24px;
  color: #bead8e;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
}

.menu-area .menu-loop .single-menu-box .menu-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 120px;
  height: 100%;
  background-size: cover;
  background-position: center;
}

@media (max-width: 575px) {
  .menu-area .menu-loop .single-menu-box .menu-img {
    height: 120px;
    left: 30px;
  }
}

.menu-area .menu-loop .single-menu-box:hover .menu-desc {
  background-color: #fff;
}

.menu-area .menu-loop .single-menu-box:hover .menu-desc .menu-link {
  right: 40px;
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .menu-area .menu-loop .single-menu-box:hover .menu-desc .menu-link {
    right: 15px;
  }
}

@media (max-width: 399px) {
  .menu-area .menu-loop .single-menu-box:hover .menu-desc .menu-link {
    right: 15px;
  }
}

.menu-area .menu-loop .single-menu-box.hover-drak:hover .menu-desc {
  background-color: #222;
}

.menu-area .menu-loop .single-menu-box.hover-drak:hover .menu-desc,
.menu-area .menu-loop .single-menu-box.hover-drak:hover .menu-desc h4 a {
  color: #fff;
}

.gallery-wrap .gallery-popup {
  overflow: hidden;
}

.gallery-wrap .gallery-popup img {
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.gallery-wrap .gallery-popup:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/*=======  CONTACT   =======*/

.contact-part .contact-info .info-box {
  padding: 50px 45px;
  background-color: #fff;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: 40px 1fr;
      grid-template-columns: 40px 1fr;
  margin-bottom: 30px;
  grid-column-gap: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-part .contact-info .info-box {
    padding: 50px 30px;
    -ms-grid-columns: 30px 1fr;
        grid-template-columns: 30px 1fr;
  }
}

@media (max-width: 767px) {
  .contact-part .contact-info .info-box {
    display: block;
    padding: 30px 20px;
  }
}

.contact-part .contact-info .info-box .icon {
  font-size: 40px;
  color: #bead8e;
}

@media (max-width: 767px) {
  .contact-part .contact-info .info-box .icon {
    margin-bottom: 15px;
  }
}

.contact-part .contact-info .info-box .desc h4 {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-part .contact-maps {
  height: 700px;
  width: 100%;
}
.contact-part .contact-maps iframe{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.contact-part .contact-form {
  background-color: #fff;
  padding: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-part .contact-form {
    padding: 50px;
  }
}

@media (max-width: 767px) {
  .contact-part .contact-form {
    padding: 50px;
  }
}

.contact-part .contact-form .main-btn.btn-filled {
  -webkit-box-shadow: none;
          box-shadow: none;
}

/*=======  Offers  =======*/
.offers-area .offer-boxes-loop .offer-box {
  margin-bottom: 60px;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc {
  background-color: #fff;
  padding: 65px 60px 60px;
  margin-left: 60px;
  margin-right: 60px;
  border-top: 6px solid #bead8e;
  margin-top: -75px;
  z-index: 2;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc {
    margin-left: 30px;
    margin-right: 30px;
    padding: 65px 40px 60px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc {
    margin-left: 20px;
    margin-right: 20px;
    padding: 40px 30px 30px;
    margin-top: -50px;
  }
}

@media (max-width: 575px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc {
    margin-left: 15px;
    margin-right: 15px;
    padding: 40px 20px 30px;
    margin-top: -30px;
  }
}

@media (max-width: 399px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc {
    margin-left: 5px;
    margin-right: 5px;
    padding: 30px 15px 20px;
  }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .main-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-top: 30px;
}

@media (max-width: 575px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .main-btn {
    padding: 0 35px;
    line-height: 40px;
  }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap {
  border-bottom: 2px solid #ececec;
  margin-bottom: 40px;
  padding-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title .room-cat {
  text-transform: uppercase;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 3px;
}

@media (max-width: 575px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title .room-cat {
    letter-spacing: 2px;
  }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title h2 {
  font-size: 48px;
  letter-spacing: -1px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title h2 {
    font-size: 32px;
  }
}

@media (max-width: 575px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .title h2 {
    font-size: 24px;
  }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
  font-size: 40px;
  color: #bead8e;
  font-weight: 700;
  text-align: right;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
    font-size: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
    font-size: 28px;
  }
}

@media (max-width: 575px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price {
    font-size: 20px;
  }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .title-wrap .price span {
  font-size: 16px;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-text,
.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature {
  font-family: "Roboto", sans-serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature {
    margin-top: 50px;
  }
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature li {
  width: 50%;
  float: left;
  margin-bottom: 20px;
  padding-left: 40px;
  position: relative;
}

.offers-area .offer-boxes-loop .offer-box .offer-desc .offer-feature li i {
  color: #bead8e;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

/*=======  Gallery  =======*/
.gallery-loop {
  display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

@media (max-width: 767px) {
  .gallery-loop {
    grid-gap: 10px;
  }
}

@media (max-width: 575px) {
  .gallery-loop {
    grid-gap: 10px;
  }
}

.gallery-loop .single-gallery-image {
  overflow: hidden;
}

.gallery-loop .single-gallery-image img {
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
}

.gallery-loop .single-gallery-image:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.gallery-loop .single-gallery-image:nth-child(3),
.gallery-loop .single-gallery-image:nth-child(6),
.gallery-loop .single-gallery-image:nth-child(9),
.gallery-loop .single-gallery-image:nth-child(12) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.gallery-loop .single-gallery-image:nth-child(3) {
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.gallery-loop .single-gallery-image:nth-child(6) {
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  grid-row: 4 / 5;
}

.gallery-loop .single-gallery-image:nth-child(9) {
  -ms-grid-row: 6;
  -ms-grid-row-span: 1;
  grid-row: 6 / 7;
}

.gallery-loop .single-gallery-image:nth-child(12) {
  -ms-grid-row: 8;
  -ms-grid-row-span: 1;
  grid-row: 8 / 9;
}

/*=======  News   =======*/
.post-box .post-desc {
  padding: 50px;
  background-color: #fff;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .post-box .post-desc {
    padding: 40px 30px;
  }
}

@media (max-width: 575px) {
  .post-box .post-desc {
    padding: 40px 30px;
    font-size: 14px;
  }
}

@media (max-width: 399px) {
  .post-box .post-desc {
    padding: 40px 20px;
  }
}

.post-box .post-desc .cat {
  margin-bottom: 25px;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  display: inline-block;
  color: #fff;
  background-color: #bead8e;
}

.post-box .post-desc .cat:hover {
  background-color: #111;
}

.post-box .post-desc h2 {
  font-size: 42px;
  margin-bottom: 20px;
  letter-spacing: -2px;
  line-height: 1;
  color: #111;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .post-box .post-desc h2 {
    font-size: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-box .post-desc h2 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .post-box .post-desc h2 {
    font-size: 30px;
  }
}

@media (max-width: 575px) {
  .post-box .post-desc h2 {
    font-size: 24px;
  }
}

.post-box .post-desc .post-meta {
  margin-bottom: 30px;
}

.post-box .post-desc .post-meta li {
  display: inline-block;
  margin-right: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .post-box .post-desc .post-meta li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-box .post-desc .post-meta li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .post-box .post-desc .post-meta li {
    margin-right: 10px;
  }
}

@media (max-width: 575px) {
  .post-box .post-desc .post-meta li {
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

.post-box .post-desc .post-meta li a {
  color: #777;
}

.post-box .post-desc .post-meta li a:hover {
  color: #bead8e;
}

.post-box .post-desc .post-meta li a i {
  margin-right: 5px;
}

.post-box .post-desc .post-footer {
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.post-box .post-desc .post-footer .author {
  font-family: "Old Standard TT", serif;
  letter-spacing: -1px;
}

.post-box .post-desc .post-footer .author a {
  color: #777;
}

.post-box .post-desc .post-footer .author img {
  margin-right: 15px;
}

.post-box .post-desc .post-footer .read-more a {
  font-size: 14px;
  color: #777;
  font-family: "Roboto", sans-serif;
}

.post-box .post-desc .post-footer .read-more a i {
  margin-right: 5px;
}

.post-box .post-desc .post-footer .read-more a:hover {
  color: #bead8e;
}

.post-box.with-video .post-media {
  position: relative;
}

.post-box.with-video .post-media .play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 80px;
  height: 80px;
  background-color: #bead8e;
  color: #fff;
  line-height: 80px;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .post-box.with-video .post-media .play-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}

.post-box.with-video .post-media .play-icon:hover {
  background-color: #111;
}

.post-box.title-with-thumb {
  position: relative;
  z-index: 1;
}

.post-box.title-with-thumb::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-image: url(../img/blog/04.jpg);
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.1;
}

.post-box.title-with-thumb .post-desc {
  background-color: transparent;
}

.post-box.title-with-thumb .post-desc .post-meta {
  margin-bottom: 0;
}

.post-box.quote-post {
  padding: 50px;
  position: relative;
  z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .post-box.quote-post {
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-box.quote-post {
    padding: 40px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .post-box.quote-post {
    padding: 40px 25px;
  }
}

@media (max-width: 575px) {
  .post-box.quote-post {
    padding: 40px 15px;
  }
}

.post-box.quote-post::before, .post-box.quote-post::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #bead8e;
  z-index: -1;
  content: '';
}

.post-box.quote-post::after {
  z-index: -1;
  opacity: 0.6;
}

.post-box.quote-post .post-desc {
  border: none;
  position: relative;
  z-index: 1;
  padding: 0;
  padding-left: 115px;
  background-color: transparent;
}

.post-box.quote-post .post-desc,
.post-box.quote-post .post-desc h2,
.post-box.quote-post .post-desc a {
  color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-box.quote-post .post-desc {
    padding-left: 75px;
  }
}

@media (max-width: 767px) {
  .post-box.quote-post .post-desc {
    padding-left: 75px;
  }
}

@media (max-width: 399px) {
  .post-box.quote-post .post-desc {
    padding-left: 0;
    padding-top: 70px;
  }
}

.post-box.quote-post .post-desc::before {
  position: absolute;
  left: 0;
  top: 5px;
  background-image: url(../img/icon/quote.png);
  width: 75px;
  height: 90px;
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  content: '';
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .post-box.quote-post .post-desc::before {
    width: 50px;
  }
}

@media (max-width: 767px) {
  .post-box.quote-post .post-desc::before {
    width: 50px;
    height: 50px;
  }
}

.post-box.quote-post .post-desc .post-meta {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .post-box.quote-post .post-desc .post-meta li {
    margin-right: 5px;
  }
}

.post-box.quote-post .post-desc .post-meta li a {
  color: #fff;
}

/*=======  News Details  =======*/
.news-details-box {
  padding: 40px;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  /*=======  Comment Template  =======*/
}

@media (max-width: 767px) {
  .news-details-box {
    padding: 30px;
    font-size: 14px;
  }
}

@media (max-width: 399px) {
  .news-details-box {
    padding: 15px;
  }
}

.news-details-box .entry-content .cat {
  margin-bottom: 25px;
  padding: 10px 30px;
  display: inline-block;
  font-weight: 700;
  color: #fff;
  background-color: #bead8e;
}

.news-details-box .entry-content .cat:hover {
  background-color: #111;
}

.news-details-box .entry-content .title,
.news-details-box .entry-content .subtitle {
  margin-bottom: 30px;
  letter-spacing: -1px;
  font-family: "Old Standard TT", serif;
}

.news-details-box .entry-content h2.title {
  font-size: 36px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-content h2.title {
    font-size: 28px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-details-box .entry-content h2.title {
    font-size: 34px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .news-details-box .entry-content h2.title {
    font-size: 28px;
  }
}

@media (max-width: 575px) {
  .news-details-box .entry-content h2.title {
    font-size: 20px;
  }
}

@media (max-width: 399px) {
  .news-details-box .entry-content h2.title {
    font-size: 18px;
  }
}

.news-details-box .entry-content h3.subtitle {
  font-size: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-content h3.subtitle {
    font-size: 24px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .news-details-box .entry-content h3.subtitle {
    font-size: 26px;
  }
}

@media (max-width: 575px) {
  .news-details-box .entry-content h3.subtitle {
    font-size: 18px;
  }
}

.news-details-box .entry-content .gap {
  height: 80px;
}

.news-details-box .entry-content .post-meta {
  margin-bottom: 25px;
}

.news-details-box .entry-content .post-meta li {
  display: inline-block;
  margin-right: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-content .post-meta li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-details-box .entry-content .post-meta li {
    margin-right: 15px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .news-details-box .entry-content .post-meta li {
    margin-right: 10px;
  }
}

@media (max-width: 575px) {
  .news-details-box .entry-content .post-meta li {
    margin-right: 15px;
    margin-bottom: 5px;
  }
}

.news-details-box .entry-content .post-meta li a {
  color: #777;
  font-size: 14px;
}

.news-details-box .entry-content .post-meta li a:hover {
  color: #bead8e;
}

.news-details-box .entry-content .post-meta li a i {
  margin-right: 5px;
}

.news-details-box .entry-content ul.list-icon li {
  padding-left: 60px;
  position: relative;
}

@media (max-width: 767px) {
  .news-details-box .entry-content ul.list-icon li {
    padding-left: 40px;
  }
}

@media (max-width: 399px) {
  .news-details-box .entry-content ul.list-icon li {
    padding-left: 25px;
  }
}

.news-details-box .entry-content ul.list-icon li:not(:last-child) {
  margin-bottom: 10px;
}

.news-details-box .entry-content ul.list-icon li::before {
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  content: '\f00c';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

@media (max-width: 767px) {
  .news-details-box .entry-content ul.list-icon li::before {
    left: 0;
  }
}

.news-details-box .entry-content blockquote {
  position: relative;
  padding: 45px 55px;
  background-color: #bead8e;
  border-radius: 10px;
  z-index: 1;
  font-size: 28px;
  font-family: "Old Standard TT", serif;
  font-weight: 700;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  letter-spacing: -1px;
  position: relative;
}

.news-details-box .entry-content blockquote::after {
  position: absolute;
  right: 40px;
  left: 40px;
  top: 40px;
  bottom: 40px;
  content: '';
  z-index: -1;
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-content blockquote {
    font-size: 22px;
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-content blockquote {
    padding: 30px 20px;
    font-size: 18px;
  }
}

@media (max-width: 399px) {
  .news-details-box .entry-content blockquote {
    font-size: 16px;
  }
}

.news-details-box .entry-content blockquote span {
  display: block;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  font-size: 14px;
  text-transform: none;
}

.news-details-box .entry-footer .tag-and-share h5 {
  font-size: 20px;
  letter-spacing: -1px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-footer .tag-and-share h5 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .tag-and-share .tag {
    margin-bottom: 30px;
  }
}

.news-details-box .entry-footer .tag-and-share .tag li {
  display: inline-block;
  margin-right: 5px;
}

.news-details-box .entry-footer .tag-and-share .tag li a {
  font-size: 12px;
  padding: 10px 20px;
  display: block;
  background-color: #f8f8f8;
  text-transform: uppercase;
  color: #777;
}

.news-details-box .entry-footer .tag-and-share .tag li a:hover {
  background-color: #bead8e;
  color: #fff;
}

.news-details-box .entry-footer .tag-and-share .share li {
  display: inline-block;
  margin-left: 25px;
}

.news-details-box .entry-footer .tag-and-share .share li a {
  color: #b5becc;
  font-size: 18px;
}

.news-details-box .entry-footer .post-nav {
  padding: 50px 0;
  border-top: 1px solid #eaeaea;
  border-bottom: 2px solid #eaeaea;
  position: relative;
}

.news-details-box .entry-footer .post-nav span.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .post-nav span.icon {
    display: none;
  }
}

.news-details-box .entry-footer .post-nav .prev-post span,
.news-details-box .entry-footer .post-nav .next-post span {
  font-size: 14px;
  font-weight: 500;
  display: block;
  color: #bead8e;
}

.news-details-box .entry-footer .post-nav .prev-post a,
.news-details-box .entry-footer .post-nav .next-post a {
  color: #222;
  font-size: 28px;
  letter-spacing: -1px;
  font-family: "Old Standard TT", serif;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-footer .post-nav .prev-post a,
  .news-details-box .entry-footer .post-nav .next-post a {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .post-nav .prev-post a,
  .news-details-box .entry-footer .post-nav .next-post a {
    font-size: 20px;
  }
}

.news-details-box .entry-footer .post-nav .prev-post {
  text-align: left;
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .post-nav .prev-post {
    margin-bottom: 30px;
  }
}

.news-details-box .entry-footer .related-post-box h3 {
  font-size: 26px;
  letter-spacing: -1px;
}

.news-details-box .entry-footer .related-post-box .thumb {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 300px;
}

.news-details-box .entry-footer .related-post-box .desc {
  font-size: 14px;
  padding: 40px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
          box-shadow: 0px 8px 16px 0px rgba(93, 93, 93, 0.06);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-footer .related-post-box .desc {
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-details-box .entry-footer .related-post-box .desc {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .related-post-box .desc {
    padding: 30px;
  }
}

.news-details-box .entry-footer .related-post-box .desc .date {
  display: inline-block;
  margin-bottom: 10px;
  color: #777;
}

.news-details-box .entry-footer .related-post-box .desc .date i {
  margin-right: 5px;
}

.news-details-box .entry-footer .related-post-box .desc h4 {
  font-size: 22px;
  margin-bottom: 20px;
  letter-spacing: -1px;
  color: #222;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-footer .related-post-box .desc h4 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .news-details-box .entry-footer .related-post-box .desc h4 {
    font-size: 22px;
  }
}

@media (max-width: 399px) {
  .news-details-box .entry-footer .related-post-box .desc h4 {
    font-size: 20px;
  }
}

.news-details-box .entry-footer .related-post-box:hover .desc {
  background-color: #f8f8f8;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.news-details-box .entry-footer .author-info-box {
  padding: 40px;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: 180px 1fr;
      grid-template-columns: 180px 1fr;
  background-color: #f8f8f8;
  grid-column-gap: 35px;
  font-size: 14px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-footer .author-info-box {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .author-info-box {
    display: block;
    text-align: center;
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .author-info-box .info-text {
    margin-top: 30px;
  }
}

.news-details-box .entry-footer .author-info-box .info-text span {
  font-weight: 700;
  margin-bottom: 5px;
  color: #bead8e;
}

.news-details-box .entry-footer .author-info-box .info-text h3 {
  font-size: 36px;
  letter-spacing: -1px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .news-details-box .entry-footer .author-info-box .info-text h3 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .news-details-box .entry-footer .author-info-box .info-text h3 {
    font-size: 22px;
  }
}

.news-details-box .comment-template h3.box-title {
  margin-bottom: 30px;
  letter-spacing: -1px;
  font-size: 26px;
}

@media (max-width: 767px) {
  .news-details-box .comment-template h3.box-title {
    font-size: 24px;
  }
}

.news-details-box .comment-template .comments-list li {
  padding-left: 130px;
  position: relative;
  font-size: 14px;
  padding-top: 15px;
  min-height: 130px;
}

@media (max-width: 575px) {
  .news-details-box .comment-template .comments-list li {
    padding-left: 0;
  }
}

.news-details-box .comment-template .comments-list li:not(:first-child) {
  padding-top: 45px;
  margin-top: 30px;
  border-top: 1px solid #eaeaea;
}

.news-details-box .comment-template .comments-list li:not(:first-child) .comment-img {
  top: 30px;
}

.news-details-box .comment-template .comments-list li .comment-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 100px;
}

.news-details-box .comment-template .comments-list li .comment-img img {
  max-width: 100px;
  max-height: 100px;
}

@media (max-width: 575px) {
  .news-details-box .comment-template .comments-list li .comment-img {
    position: unset;
    margin-bottom: 30px;
  }
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top {
  margin-bottom: 20px;
  position: relative;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top h6 {
  font-size: 18px;
  letter-spacing: -1px;
  margin-bottom: 10px;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top span.date {
  font-weight: 700;
  font-size: 12px;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top .reply-link {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 10px 20px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  z-index: 2;
  color: #777;
}

.news-details-box .comment-template .comments-list li .comment-desc .desc-top .reply-link i {
  margin-right: 5px;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .news-details-box .comment-template .comments-list li .children {
    margin-left: -80px;
  }
}

@media (max-width: 575px) {
  .news-details-box .comment-template .comments-list li .children {
    padding-left: 30px;
  }
}

.news-details-box .comment-template .comments-list li .children li:first-child {
  padding-top: 45px;
  margin-top: 30px;
  border-top: 1px solid #eaeaea;
}

.news-details-box .comment-template .comments-list li .children li:first-child .comment-img {
  top: 30px;
}

.news-details-box .comment-template .comment-form {
  padding: 45px;
  background-color: #f8f8f8;
}

@media (max-width: 575px) {
  .news-details-box .comment-template .comment-form {
    padding: 20px;
  }
}

@media (max-width: 399px) {
  .news-details-box .comment-template .comment-form {
    padding-left: 15px;
  }
}

.news-details-box .comment-template .comment-form textarea {
  height: 150px;
  padding-top: 25px;
}

.news-details-box .comment-template .comment-form .main-btn {
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media (max-width: 575px) {
  .news-details-box .comment-template .comment-form .main-btn {
    padding: 0px 30px;
  }
}

/*=======  Paginations  =======*/
.pagination-wrap {
  text-align: center;
  padding-top: 20px;
}

.pagination-wrap ul li {
  display: inline-block;
  margin: 0 5px;
}

@media (max-width: 575px) {
  .pagination-wrap ul li {
    margin-bottom: 8px;
  }
}

.pagination-wrap ul li a {
  height: 50px;
  width: 50px;
  line-height: 50px;
  color: #777;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  color: #111;
}

.pagination-wrap ul li a:hover {
  background-color: #bead8e;
  color: #fff;
}

.pagination-wrap ul li.active a {
  background-color: #bead8e;
  color: #fff;
}

/*=======  Sidebars  =======*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar {
    margin-top: 80px;
  }
}

@media (max-width: 767px) {
  .sidebar {
    margin-top: 80px;
  }
}

.sidebar .widget {
  padding: 40px;
  background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar .widget {
    padding: 40px 20px;
  }
}

@media (max-width: 399px) {
  .sidebar .widget {
    padding: 40px 20px;
  }
}

.sidebar .widget .widget-title {
  font-size: 20px;
  margin-bottom: 40px;
  line-height: 1;
  position: relative;
  padding-left: 30px;
}

.sidebar .widget .widget-title::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #bead8e;
  content: '';
}

.sidebar .widget.about-author-widget .author-box {
  font-size: 14px;
  text-align: center;
}

.sidebar .widget.about-author-widget .author-box h6 {
  font-size: 24px;
  letter-spacing: -1px;
  margin-top: 30px;
  padding-bottom: 20px;
}

.sidebar .widget.about-author-widget .author-box .social-icon {
  margin-top: 25px;
}

.sidebar .widget.about-author-widget .author-box .social-icon li {
  display: inline-block;
  margin: 0 5px;
}

.sidebar .widget.about-author-widget .author-box .social-icon li a {
  padding: 5px;
  color: #b3bed3;
}

.sidebar .widget.about-author-widget .author-box .social-icon li a:hover {
  color: #bead8e;
}

.sidebar .widget.search-widget form {
  position: relative;
}

.sidebar .widget.search-widget form input {
  width: 100%;
  background-color: #f8f8f8;
  height: 60px;
  border: none;
  padding-left: 30px;
  padding-right: 60px;
  font-size: 14px;
}

.sidebar .widget.search-widget form button {
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
  height: 100%;
  z-index: 1;
  background-color: #bead8e;
  font-size: 14px;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sidebar .widget.search-widget form button:hover {
  background-color: #111;
  color: #fff;
}

.sidebar .widget.popular-feeds .single-popular-feed {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  -ms-grid-columns: 80px 1fr;
      grid-template-columns: 80px 1fr;
  grid-column-gap: 15px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e1e6ff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.sidebar .widget.popular-feeds .single-popular-feed:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc h6 {
  font-size: 18px;
  margin-bottom: 10px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time {
  font-size: 14px;
}

.sidebar .widget.popular-feeds .single-popular-feed .feed-desc .time i {
  margin-right: 5px;
}

.sidebar .widget.categories-widget ul li {
  margin-bottom: 10px;
}

.sidebar .widget.categories-widget ul li:last-child {
  margin-bottom: 0;
}

.sidebar .widget.categories-widget ul li a {
  font-size: 14px;
  padding-left: 30px;
  height: 50px;
  background-color: #f8f8f8;
  display: block;
  position: relative;
  line-height: 50px;
  color: #777;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sidebar .widget.categories-widget ul li a span {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: #eaeaea;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sidebar .widget.categories-widget ul li a:hover {
  background-color: #bead8e;
  color: #fff;
}

.sidebar .widget.categories-widget ul li a:hover span {
  background-color: #947f58;
}

.sidebar .widget.socail-widget ul li {
  display: inline-block;
  margin-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar .widget.socail-widget ul li {
    margin-right: 5px;
  }
}

@media (max-width: 399px) {
  .sidebar .widget.socail-widget ul li {
    margin-right: 5px;
  }
}

.sidebar .widget.socail-widget ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  color: #777;
  background-color: #f8f8f8;
  text-align: center;
}

.sidebar .widget.socail-widget ul li a:hover {
  background-color: #bead8e;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter {
  margin-bottom: 30px;
  padding-bottom: 30px;
  font-size: 12px;
  border-bottom: 1px solid #e1e6ff;
  padding-left: 35px;
  position: relative;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '\f099';
  font-family: 'Font Awesome 5 Brands';
  font-size: 14px;
  color: #bead8e;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter a {
  color: #111;
}

.sidebar .widget.twitter-feed-widget .twitter-looop .single-twitter .date {
  margin-top: 15px;
  font-weight: 500;
}

.sidebar .widget.instagram-feed-widget ul {
  display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}
.sidebar .widget.instagram-feed-widget ul li img{
  width: 100%;
}

.sidebar .widget.popular-tag-widget ul li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
}

.sidebar .widget.popular-tag-widget ul li a {
  font-size: 12px;
  color: #777;
  padding: 10px 20px;
  background-color: #f4f4f4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sidebar .widget.popular-tag-widget ul li a {
    padding: 10px 15px;
  }
}

@media (max-width: 399px) {
  .sidebar .widget.popular-tag-widget ul li a {
    padding: 10px 15px;
  }
}

.sidebar .widget.popular-tag-widget ul li a:hover {
  background-color: #bead8e;
}

.sidebar .widget.banner-ad-widget {
  padding: 0;
  border: none;
}

/*=======  BOOKING FORM  =======*/
.booking-form {
  position: relative;
  margin-top: -70px;
  z-index: 2;
}

.booking-form .booking-form-inner {
  background-color: #fff;
  padding: 10px 40px 40px;
}

.booking-form form input,
.booking-form form select,
.booking-form form .nice-select {
  background-color: transparent;
  width: 100%;
  height: 50px;
  font-size: 14px;
  border: none;
  border-bottom: 2px solid #bead8e;
  line-height: 50px;
  color: rgba(119, 119, 119, 0.5);
}

.booking-form form .nice-select {
  border-color: #e9e9e9;
  padding-left: 0;
}

.booking-form form .nice-select::after {
  display: none;
}

.booking-form form .nice-select::before {
  left: 0;
}

.booking-form form label {
  color: #222;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  display: block;
  margin: 0;
}

.booking-form form .inputs-filed {
  position: relative;
}

.booking-form form .inputs-filed input{
  padding: 0 20px;
}
.booking-form form .inputs-filed .nice-select{
  padding: 0px;
  border:none;
}
.booking-form form .inputs-filed .nice-select select{
  margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-appearance: none;
}
.booking-form form .inputs-filed .icon {
  position: absolute;
  left: 0;
  bottom: 15px;
  font-size: 14px;
  color: #bead8e;
}

.booking-form form button {
  width: 100%;
  border: none;
  background-color: #bead8e;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  height: 60px;
  letter-spacing: 2px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.booking-form form button:hover {
  background-color: #111;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .booking-form form button {
    font-size: 12px;
    letter-spacing: 1px;
  }
}

.booking-form.booking-form-dark {
  background-color: #222;
  margin-top: 0;
}

.booking-form.booking-form-dark .booking-form-inner {
  background-color: transparent;
}

.booking-form.booking-form-dark form label {
  color: #fff;
}

.booking-form.boxed-layout {
  margin-top: -40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .booking-form.boxed-layout {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .booking-form.boxed-layout {
    margin-top: 0;
  }
}

.booking-form.boxed-layout form button {
  font-size: 12px;
  letter-spacing: 1;
}

/*=======  Places   =======*/
.places-wrapper .places-maps {
  width: 100%;
  height: 700px;
}

.places-wrapper .gm-style .gm-style-iw-c {
  padding: 0;
  border-radius: 0;
}

.places-wrapper .gm-style .gm-style-iw-d {
  overflow: hidden !important;
  width: 290px;
}

.places-wrapper .map-info-window .info-window-img {
  width: 100%;
  height: 190px;
  background-size: cover;
  background-position: center;
}

.places-wrapper .map-info-window .info-content {
  text-align: center;
  padding: 20px 10px;
  font-size: 14px;
}

.places-wrapper .map-info-window .info-content h4 {
  margin-bottom: 5px;
}

.places-boxes .place-box .place-bg-wrap {
  overflow: hidden;
}

.places-boxes .place-box .place-bg-wrap .place-bg {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 190px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 575px) {
  .places-boxes .place-box .place-bg-wrap .place-bg {
    height: 220px;
  }
}

.places-boxes .place-box:hover .place-bg {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.places-boxes .place-box .desc {
  background-color: #fff;
  padding: 20px;
  text-align: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .places-boxes .place-box .desc {
    padding: 20px 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .places-boxes .place-box .desc {
    padding: 20px 15px;
  }
}

.places-boxes .place-box .desc h4 {
  font-size: 24px;
  margin-bottom: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .places-boxes .place-box .desc h4 {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .places-boxes .place-box .desc h4 {
    font-size: 22px;
  }
}

.places-boxes .place-box .desc .time {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

/*=======  Places Details  =======*/
.places-details .title-wrap .place-cat {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding-bottom: 5px;
  letter-spacing: 2px;
}

@media (max-width: 575px) {
  .places-details .title-wrap .place-cat {
    letter-spacing: 0;
    font-size: 12px;
  }
}

.places-details .title-wrap h2 {
  font-size: 48px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .places-details .title-wrap h2 {
    font-size: 38px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .places-details .title-wrap h2 {
    font-size: 32px;
  }
}

@media (max-width: 575px) {
  .places-details .title-wrap h2 {
    font-size: 28px;
  }
}

@media (max-width: 399px) {
  .places-details .title-wrap h2 {
    font-size: 24px;
  }
}

.places-details .title-wrap .link a {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  background-color: #fff;
  color: #777;
  padding: 25px 45px;
  letter-spacing: 2px;
}

@media (max-width: 767px) {
  .places-details .title-wrap .link a {
    padding: 20px 30px;
  }
}

@media (max-width: 575px) {
  .places-details .title-wrap .link a {
    padding: 15px 25px;
    letter-spacing: 0px;
  }
}

.places-details .title-wrap .link a:hover {
  background-color: #bead8e;
  color: #fff;
}

/*=======  Restaurant CSS  =======*/
.restaurant-tab-area .restaurant-rood-list .nav-link {
  padding: 0;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background-color: #fff;
  text-align: center;
  display: block;
  padding: 35px 25px;
  margin-bottom: 30px;
}

@media (max-width: 575px) {
  .restaurant-tab-area .restaurant-rood-list .nav-link {
    padding: 25px;
  }
}

.restaurant-tab-area .restaurant-rood-list .nav-link .title {
  display: block;
  font-size: 12px;
  font-weight: 700;
  padding-top: 20px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  color: #222;
}

.restaurant-tab-area .restaurant-rood-list .nav-link i {
  color: #bead8e;
  font-size: 60px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 575px) {
  .restaurant-tab-area .restaurant-rood-list .nav-link i {
    font-size: 40px;
  }
}

.restaurant-tab-area .restaurant-rood-list .nav-link.active, .restaurant-tab-area .restaurant-rood-list .nav-link:hover {
  background-color: #bead8e;
}

.restaurant-tab-area .restaurant-rood-list .nav-link.active i,
.restaurant-tab-area .restaurant-rood-list .nav-link.active .title, .restaurant-tab-area .restaurant-rood-list .nav-link:hover i,
.restaurant-tab-area .restaurant-rood-list .nav-link:hover .title {
  color: #fff;
}

.restaurant-tab-area .food-box {
  margin-bottom: 30px;
}

@media (max-width: 399px) {
  .restaurant-tab-area .food-box {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.restaurant-tab-area .food-box .thumb {
  position: relative;
}

.restaurant-tab-area .food-box .thumb img {
  width: 100%;
}

.restaurant-tab-area .food-box .thumb .price {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #bead8e;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  padding: 8px 15px;
}

.restaurant-tab-area .food-box .desc {
  padding: 30px;
  background-color: #fff;
  position: relative;
}

@media (max-width: 575px) {
  .restaurant-tab-area .food-box .desc {
    padding: 15px;
  }
}

.restaurant-tab-area .food-box .desc a.cat {
  font-size: 12px;
  color: #777;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .restaurant-tab-area .food-box .desc a.cat {
    letter-spacing: 1px;
  }
}

@media (max-width: 399px) {
  .restaurant-tab-area .food-box .desc a.cat {
    letter-spacing: 0;
  }
}

.restaurant-tab-area .food-box .desc h4 {
  font-size: 24px;
}

@media (max-width: 575px) {
  .restaurant-tab-area .food-box .desc h4 {
    font-size: 20px;
  }
}

@media (max-width: 399px) {
  .restaurant-tab-area .food-box .desc h4 {
    font-size: 18px;
  }
}

.restaurant-tab-area .food-box .desc .link {
  color: #d2d2d2;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  visibility: hidden;
  opacity: 0;
}

.restaurant-tab-area .food-box:hover .desc .link {
  visibility: visible;
  opacity: 1;
  right: 30px;
}

@media (max-width: 575px) {
  .restaurant-tab-area .food-box:hover .desc .link {
    right: 10px;
  }
}

.menu-area.gradient-white {
  background: linear-gradient(45deg, #f8f8f8 0%, white 100%);
}

.feature-room-section.with-bg {
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.feature-room-section.with-bg::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: #222;
  opacity: 0.6;
  z-index: -1;
}

/*=======  FOOTER  =======*/

  /*=======  Subscibe  =======*/
  /*=======  Widgets  =======*/
  /*=======  Copy Right  =======*/


footer .footer-subscibe-area {
  background-color: #fff;
}

footer .footer-subscibe-area .subscribe-text .subscribe-form {
  position: relative;
}

footer .footer-subscibe-area .subscribe-text .subscribe-form input {
  width: 100%;
  border: none;
  height: 90px;
  background-color: #f8f8f8;
  padding-left: 50px;
  padding-right: 230px;
  font-size: 16px;
  letter-spacing: 3px;
  color: #aeaeae;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  footer .footer-subscibe-area .subscribe-text .subscribe-form input {
    padding-left: 20px;
    padding-right: 200px;
  }
}

@media (max-width: 575px) {
  footer .footer-subscibe-area .subscribe-text .subscribe-form input {
    padding-left: 20px;
    padding-right: 20px;
    height: 70px;
  }
}

footer .footer-subscibe-area .subscribe-text .subscribe-form button {
  position: absolute;
  right: 15px;
  bottom: 15px;
  top: 15px;
  background-color: #bead8e;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  width: 200px;
  letter-spacing: 3px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  footer .footer-subscibe-area .subscribe-text .subscribe-form button {
    width: 180px;
  }
}

@media (max-width: 575px) {
  footer .footer-subscibe-area .subscribe-text .subscribe-form button {
    position: unset;
    width: 100%;
    height: 60px;
  }
}

footer .footer-subscibe-area .subscribe-text .subscribe-form button:hover {
  background-color: #111;
}

footer .footer-widget-area {
  z-index: 1;
  background-color: #222;
}

footer .footer-widget-area::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-size: cover;
  background-repeat: repeat;
  background-position: center;
  z-index: -1;
}

footer .footer-widget-area .widget,
footer .footer-widget-area .widget a,
footer .footer-widget-area .widget .widget-title,
footer .footer-widget-area .widget h6 {
  color: #fff;
}

footer .footer-widget-area .widget h4.widget-title {
  font-size: 24px;
  margin-bottom: 4px;
  margin-bottom: 40px;
}

footer .footer-widget-area .widget.site-info-widget {
  color: #bababa;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  footer .footer-widget-area .widget.site-info-widget {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  footer .footer-widget-area .widget.site-info-widget {
    font-size: 14px;
  }
}

footer .footer-widget-area .widget.site-info-widget .social-links {
  text-align: left;
}

footer .footer-widget-area .widget.site-info-widget .social-links a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  background-color: #2e2e2e;
  margin-right: 5px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  footer .footer-widget-area .widget.site-info-widget .social-links a {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 14px;
    margin-right: 2px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  footer .footer-widget-area .widget.site-info-widget .social-links a {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 14px;
    margin-right: 2px;
  }
}

footer .footer-widget-area .widget.site-info-widget .social-links a:hover {
  background-color: #bead8e;
}

footer .footer-widget-area .widget.nav-widget {
  padding-left: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .footer-widget-area .widget.nav-widget {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  footer .footer-widget-area .widget.nav-widget {
    padding-left: 0;
  }
}

footer .footer-widget-area .widget.nav-widget ul li {
  margin-bottom: 35px;
  width: 50%;
  float: left;
}

@media (max-width: 399px) {
  footer .footer-widget-area .widget.nav-widget ul li {
    margin-bottom: 20px;
    width: 100%;
  }
}

footer .footer-widget-area .widget.nav-widget ul li a {
  font-family: "Roboto", sans-serif;
  position: relative;
  padding-left: 20px;
}

footer .footer-widget-area .widget.nav-widget ul li a:hover {
  padding-left: 30px;
  color: #bead8e;
}

footer .footer-widget-area .widget.nav-widget ul li a::before {
  position: absolute;
  left: 0;
  top: 0;
  content: '+';
}

footer .footer-widget-area .widget.nav-widget ul li:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .footer-widget-area .widget.contact-widget {
    padding-left: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  footer .footer-widget-area .widget.contact-widget {
    padding-left: 20px;
  }
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #2e2e2e;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40px 1fr;
      grid-template-columns: 40px 1fr;
  -ms-grid-rows: 1fr;
      grid-template-rows: 1fr;
  grid-column-gap: 20px;
  font-size: 14px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box {
    grid-column-gap: 15px;
  }
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box h6 {
  font-size: 20px;
  margin-bottom: 10px;
}

footer .footer-widget-area .widget.contact-widget .contact-lists .contact-box .icon {
  font-size: 40px;
  color: #bead8e;
}


footer .footer-widget-area.light-theme::after {
  display: none;
}

footer .footer-widget-area.light-theme .widget,
footer .footer-widget-area.light-theme .widget a {
  color: #777777;
}

footer .footer-widget-area.light-theme .widget .widget-title,
footer .footer-widget-area.light-theme .widget h6 {
  color: #222;
}

footer .footer-widget-area.light-theme .widget.site-info-widget .social-links a {
  background-color: #f8f8f8;
  color: #777777;
}

footer .footer-widget-area.light-theme .widget.site-info-widget .social-links a:hover {
  background-color: #bead8e;
}

footer .footer-widget-area.light-theme .widget.contact-widget .contact-box {
  border-color: #e1e1e1;
}

footer .copyright-area {
  background-color: #111;
  color: #fff;
}

.whatsapp {
  width: 200 !important;
}

footer .copyright-area .social-links {
  text-align: right;
}

@media (max-width: 767px) {
  footer .copyright-area .social-links {
    text-align: center;
    margin-bottom: 10px;
  }
}

footer .copyright-area .social-links a {
  height: 60px;
  width: 60px;
  line-height: 60px;
  text-align: center;
  color: #fff;
  background-color: #212121;
  margin-left: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer .copyright-area .social-links a {
    margin-left: 5px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

@media (max-width: 767px) {
  footer .copyright-area .social-links a {
    margin: 0 5px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

@media (max-width: 575px) {
  footer .copyright-area .social-links a {
    margin: 0 2px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}

footer .copyright-area .social-links a:hover {
  background-color: #bead8e;
}

footer .copyright-area .copyright-text {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  footer .copyright-area .copyright-text {
    letter-spacing: 0;
  }
}

footer .copyright-area .copyright-text a {
  color: #fff;
  text-decoration: underline;
}

footer .copyright-area .copyright-text.copyright-two {
  text-transform: none;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 0;
}

@media (max-width: 767px) {
  footer .copyright-area .copyright-text {
    margin-top: 10px;
    text-align: center;
  }
}

footer .copyright-area .footer-menu ul li {
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-right: 15px;
}

@media (max-width: 399px) {
  footer .copyright-area .footer-menu ul li {
    padding-left: 0;
    margin-right: 0;
  }
}

footer .copyright-area .footer-menu ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 3px;
  height: 12px;
  background-color: #fff;
  content: '';
}

@media (max-width: 399px) {
  footer .copyright-area .footer-menu ul li::before {
    display: none;
  }
}

footer .copyright-area .footer-menu ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

footer .copyright-area .footer-menu ul li:first-child::before {
  display: none;
}

footer .copyright-area .footer-menu ul li a {
  color: #fff;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}

footer .copyright-area .footer-menu ul li a:hover {
  color: #bead8e;
}

footer .copyright-area.light-theme {
  background-color: #f8f8f8;
}

footer .copyright-area.light-theme,
footer .copyright-area.light-theme a,
footer .copyright-area.light-theme .copyright-text,
footer .copyright-area.light-theme .footer-menu ul li a {
  color: #222;
}

footer .copyright-area.light-theme .footer-menu ul li::before {
  background-color: #737373;
}

/*=======  Back to top  =======*/
.back-to-top {
  position: fixed;
  right: 0;
  bottom: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #bead8e;
  z-index: 99;
  color: #fff;
  font-size: 18px;
  opacity: 0;
  visibility: hidden;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
  right: 40px;
}

.back-to-top:hover {
  background-color: #222;
}

.styles-module_whatsappChatBox_P75uz.styles-module_open_2W1cE {
  z-index: 1 !important;
}
